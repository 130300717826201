import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import { Link, useNavigate } from 'react-router-dom';
import { Typography, AppBar, Tooltip, IconButton, Avatar, Menu, MenuItem } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { isMobile } from 'react-device-detect';
import { onAuthStateChanged, User } from 'firebase/auth';
import { auth } from '../../libs/firebase';
import Cookies from 'js-cookie';
import { Capacitor } from '@capacitor/core';
import { getPhotoUrl, removeAllPref } from '../../utils/mobileStorage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import mobileSelectedStore from '../../store/mobileSelectedStore';
import { observer } from 'mobx-react-lite';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';


const Container = styled(AppBar)`
    height: ${Capacitor.getPlatform() === 'ios' ? '' : '60px'};
    background-color: #192633;
    border-bottom: 2px solid;
    border-color: #38444D;
    display: flex;
    overflow-y: hidden;
    overflow-x: hidden;
    
`
const Wrapper = styled.div`
    height: ${Capacitor.getPlatform() === 'ios' ? '' : '60px'};
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: env(safe-area-inset-top);
`

const Center = styled.div`
    flex: 2.5;
    margin-left: 10px;
    display: flex; 
    flex-direction: row; 
    align-items: center;
    
`
const Logo = styled(Typography)`
    font-weight: bold;
    color: white;
    padding-left: 4%;
    white-space: nowrap; 
    :hover{
        text-decoration: underline;
        text-decoration-color: #2C3640;
        background-color: #2C3640;
    }
`
const MobileLogo = styled(Typography)`
    font-weight: bold;
    color: white;
    padding-left: 4%;
    white-space: nowrap; 
`

const Right = styled.div`
    flex: 0.5;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`

const MenuItems = styled.div`  
    cursor: pointer;
    margin-right: ${isMobile ? '5px' : '20px'};
`
const MobileTopBar = (props: any) => {
    const navigate = useNavigate()
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const [displayArrow, setDisplayArrow] = useState(false)
    const [photoUrl, setPhotoUrl] = useState(sessionStorage.getItem('photoUrl') || '')
    useEffect(() => {
        const getPhoto = async () => {
            if (!sessionStorage.getItem('photoUrl')) {
                await getPhotoUrl()
                setPhotoUrl(sessionStorage.getItem('photoUrl') || '')
            }
        }
        getPhoto()
        const url = window.location.href.split('/').pop();
        if (url === 'loggedroutine') {
            setDisplayArrow(true)
        }
        else if (url === '1' || url === '2' || url === '3' || url === '4' || url === '5' || url === '6' || url === '7') {
            setDisplayArrow(true)
        } else {
            setDisplayArrow(false)
        }

    }, [])

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleExitButton = () => {
        localStorage.removeItem('routineState')
        //check url
        const url = window.location.href.split('/').pop();
        if (mobileSelectedStore.fromProgressPage) {
            mobileSelectedStore.setCurrentlySelected(2)
            mobileSelectedStore.setFromProgressPage(false)
        }
        else if (url === 'loggedroutine') {
            mobileSelectedStore.setCurrentlySelected(1)
        }
        else if (url === '1' || url === '2' || url === '3' || url === '4' || url === '5' || url === '6' || url === '7') {
            mobileSelectedStore.setCurrentlySelected(0)
        }
        navigate(-1)
    }

    const handleLogout = async () => {
        if (Capacitor.isNativePlatform()) {
            await removeAllPref()
            sessionStorage.clear()
            localStorage.removeItem('routineState')
            mobileSelectedStore.setCurrentlySelected(1)
            await FirebaseAuthentication.signOut()
        }
        else {

            Cookies.remove('jwt')
            Cookies.remove('userId')
            Cookies.remove('photoUrl')
            //clear storage 
            sessionStorage.clear()
            mobileSelectedStore.setCurrentlySelected(1)
            localStorage.removeItem('routineState')
            await auth.signOut()
        }
        navigate('/login')
    }
    return (
        <>
            <Container position='static'>
                <Wrapper>
                    <Center>
                        {displayArrow && <ArrowBackIcon onClick={handleExitButton} />}
                        {
                            isMobile
                                ? <MobileLogo variant={'h5'}>{props.title}</MobileLogo>
                                : <Logo variant={'h5'}>{props.title}</Logo>
                        }
                    </Center>
                    <Right>
                        <MenuItems>
                            <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    {photoUrl ?
                                        <Avatar src={photoUrl} imgProps={{ referrerPolicy: "no-referrer" }} sx={{ width: isMobile ? '30px' : '40px', height: isMobile ? '30px' : '40px' }} />
                                        : <AccountCircle sx={{ color: 'white' }} />}
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                <MenuItem onClick={handleLogout}>
                                    <Typography textAlign="center">Logout</Typography>
                                </MenuItem>
                            </Menu>
                        </MenuItems>
                    </Right>
                </Wrapper>
            </Container >
        </>
    )
}

export default observer(MobileTopBar)