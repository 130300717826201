// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { GoogleAuthProvider, getAuth, signInWithPopup, createUserWithEmailAndPassword, signInWithEmailAndPassword, setPersistence, browserLocalPersistence, indexedDBLocalPersistence, initializeAuth, OAuthProvider } from "firebase/auth";
import { Capacitor } from "@capacitor/core";
import { getApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const getFirebaseAuth = async () => {
    if (Capacitor.isNativePlatform()) {
        return initializeAuth(getApp(), {
            persistence: indexedDBLocalPersistence,
        });
    } else {
        return getAuth();
    }
};

const provider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider('apple.com')

export const auth = getAuth(getApp());
setPersistence(auth, Capacitor.isNativePlatform() ? indexedDBLocalPersistence : browserLocalPersistence);

export const signInWithGoogle = async () => signInWithPopup(auth, provider)
export const signInWithApple = async () => signInWithPopup(auth, appleProvider)

export const loginWithEmail = async (email, password) => signInWithEmailAndPassword(auth, email, password)
export const signupWithEmail = async (email, password) => createUserWithEmailAndPassword(auth, email, password)
