import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { isMobile } from 'react-device-detect';
import { Capacitor } from '@capacitor/core';

export const baseContainerStyles = css`
  width: 100%;
  height: 100%;
  background: #192633;
  position: fixed;
  display: flex;
  justify-content: center;
  overflow: auto;
  /* padding-bottom: ${isMobile ? (Capacitor.getPlatform() === 'ios' ? 'calc(env(safe-area-inset-top) + 56px)' : '56px') : ''}; */
`;