import axios from "axios";
import config from "../config";
import { makeAutoObservable, runInAction, toJS } from "mobx";
import type DailyRoutine from "../types/DailyRoutine";
import type WorkoutRoutine from "../types/WorkoutRoutine";
import Cookies from "js-cookie";
import ErrorConfig from "../utils/ErrorConfig";
import { checkCookies } from "../libs/api";
import { getToken, getUserId } from "../utils/mobileStorage";

class WorkoutRoutineStore {
    workoutRoutine: WorkoutRoutine[] = [];
    dailyRoutines: DailyRoutine[] = [];
    constructor() {
        makeAutoObservable(this);
    }

    async fetchRoutines() {
        try {
            await checkCookies()
            const fetchData = {
                userId: await getUserId()
                //userId: '9d51c2fb-b664-4dfa-a009-4d0e5bf3c991'
            }
            const res = await axios.post(config.backend.url + 'workoutRoutine/get', fetchData, {
                headers: {
                    "Authorization": await getToken()
                }
            });
            runInAction(() => {
                this.workoutRoutine = res.data?.data?.result;
                this.dailyRoutines = res.data?.data?.result[0]?.workoutDay || [];

                // this.dailyRoutines = res.data?.data?.result[0].workoutDay;
            })
        }
        catch (e: any) {
            ErrorConfig(e);
            const errorMessage = e?.response?.data?.error?.message;
            console.log(errorMessage);
        }
    }

    getWorkoutRoutine() {
        return this.workoutRoutine;
    }
    getDailyRoutines() {
        return this.dailyRoutines;
    }
}


const workoutRoutineStore = new WorkoutRoutineStore();
export default workoutRoutineStore;
