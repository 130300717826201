import axios from "axios";
import config from "../config";
import { makeAutoObservable, runInAction, toJS } from "mobx";
import type DailyRoutine from "../types/DailyRoutine";
import LoggedRoutine from "../types/Logged/LoggedRoutine";
import Cookies from "js-cookie";
import ErrorConfig from "../utils/ErrorConfig";
import { checkCookies } from "../libs/api";
import { getToken, getUserId } from "../utils/mobileStorage";

class LoggedRoutineStore {
    loggedRoutine: LoggedRoutine[] = [];
    constructor() {
        makeAutoObservable(this);
        //this.fetchRoutines();
    }

    async fetchRoutines() {
        try {
            await checkCookies()
            const todayDate = new Date();
            const data = {
                userId: await getUserId(),
                date: todayDate
            }
            const res = await axios.post(config.backend.url + 'loggedWorkout/search/date', data, {
                headers: {
                    "Authorization": await getToken()
                }
            });
            runInAction(() => {
                this.loggedRoutine = res.data?.data?.LoggedWorkouts;
            })
        }


        catch (e: any) {
            ErrorConfig(e);
            const errorMessage = e?.response?.data?.error?.message;
            console.log(errorMessage);
        }

    }
}

const loggedRoutineStore = new LoggedRoutineStore();
export default loggedRoutineStore;