import { useEffect, useState } from "react"
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { auth } from '../../libs/firebase'
import styled from "@emotion/styled";
import Cookies from "js-cookie";
import config from "../../config";
import { User } from "firebase/auth";
import getCurrentUser from "../../utils/GetCurrentUser";
import { checkCookies } from "../../libs/api";
import { Preferences } from '@capacitor/preferences';
import { isMobile } from "react-device-detect";
import { Capacitor } from "@capacitor/core";
import { removeAllPref, setPhotoUrl } from "../../utils/mobileStorage";
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import mobileSelectedStore from "../../store/mobileSelectedStore";
import CircularProgress from "@mui/material/CircularProgress";


const LoadingContainer = styled.div`
    height: 100vh;
    width: 100vw;
    background-color: #192633;
`



const RequireAuth = () => {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [loggedUser, setLoggedUser] = useState<User | null>(null)

    useEffect(() => {
        setIsLoading(true)
        async function getNewToken() {
            const user = await getCurrentUser()
            if (user && Object.keys(user).length !== 0) {
                setLoggedUser(user)
                await checkCookies()
                if (user?.photoURL) {
                    if (Capacitor.isNativePlatform()) {
                        setPhotoUrl(user.photoURL)
                    }
                    else {
                        Cookies.set('photoUrl', user.photoURL, { expires: 30, secure: config.cookieSecure })
                    }
                }
            }
            else {
                if (Capacitor.isNativePlatform()) {
                    await removeAllPref()
                    sessionStorage.clear()
                    localStorage.removeItem('routineState')
                    mobileSelectedStore.setCurrentlySelected(1)
                    await FirebaseAuthentication.signOut()
                }
                else {
                    Cookies.remove('jwt')
                    Cookies.remove('userId')
                    Cookies.remove('photoUrl')
                }
                navigate('/login')
            }
        }
        getNewToken()
        //get googles token and compare with one stored in a cookie if they are the same we good if not, update cookies
        // auth.onIdTokenChanged(async (innerUser) => {
        //     const user = await getCurrentUser()
        //     if (user) {
        //         const token = await user.getIdToken()
        //         // const token = await user.getIdToken(true)
        //         Cookies.set('jwt', token, { expires: 30, secure: config.cookieSecure });
        //         Cookies.set('userId', user.uid, { expires: 30, secure: config.cookieSecure });
        //         if (user.photoURL) {
        //             Cookies.set('photoUrl', user.photoURL, { expires: 30, secure: config.cookieSecure })
        //         }
        //     }
        // })
        // auth.onAuthStateChanged(async (innerUser) => {
        //     //check if cookies exist, if not redirect to login\
        //     const user = await getCurrentUser()
        //     if (user?.photoURL) {
        //         Cookies.set('photoUrl', user.photoURL, { expires: 30, secure: config.cookieSecure })
        //     }
        //     //get token
        //     const token = await user?.getIdToken()
        //     if (token && user) {
        //         //check if tokens match
        //         const cookieToken = Cookies.get('jwt')
        //         if (cookieToken !== token) {
        //             //if tokens dont match, update cookies
        //             Cookies.set('jwt', token, { expires: 30, secure: config.cookieSecure });
        //             Cookies.set('userId', user.uid, { expires: 30, secure: config.cookieSecure });
        //         }
        //     }
        //     if (!Cookies.get('userId') || !Cookies.get('jwt')) {
        //         Cookies.remove('jwt')
        //         Cookies.remove('userId')
        //         Cookies.remove('photoUrl')
        //         auth.signOut()
        //         navigate('/login')
        //     }
        // })
        setIsLoading(false)
        // }, [auth.onAuthStateChanged, auth.onIdTokenChanged])
    }, [])

    return (
        isLoading || !loggedUser
            ?
            <LoadingContainer>
                {
                    Capacitor.isNativePlatform() ?
                        <div style={{ position: 'absolute', top: '10%', width: '100%', textAlign: 'center' }}>
                            <CircularProgress />
                        </div>
                        :
                        <div style={{ color: "white" }}>Redirecting you...</div>
                }
            </LoadingContainer>
            :
            <Outlet />


    )
}

export default RequireAuth