import { useEffect, useState } from 'react';
import { TextField, Autocomplete, Card, CardContent, CardHeader, Typography, Button, CircularProgress } from "@mui/material";
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import { useNavigate } from 'react-router-dom';
import styled from "@emotion/styled";
import axios from 'axios';
import config from '../../config';
import { set, toJS, reaction, autorun } from "mobx";
import { observer } from 'mobx-react-lite';
import workoutRoutineStore from '../../store/workoutRoutineStore';
import GetRoutine from '../../utils/GetRoutine';
import LoadingButton from '@mui/lab/LoadingButton';
import WorkoutRoutine from '../../types/WorkoutRoutine';
import SaveIcon from '@mui/icons-material/Save';
import CheckIcon from '@mui/icons-material/Check';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { isMobile } from 'react-device-detect';
import { UsePost, UsePut } from '../../libs/api';
import { baseContainerStyles } from '../styled/baseStyles';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';
import { Capacitor } from '@capacitor/core';
//TODO limit title size being displayed for dailyroutine 

const WorkoutRoutineComponent = () => {
    const navigate = useNavigate();
    const { putLoading, putResponse, callPut } = UsePut()
    const { postLoading, postData, callPost } = UsePost()

    const [titleError, setTitleError] = useState(false);
    const [descError, setDescError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDataloading, setIsDataLoading] = useState(true);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isSaved, setIsSaved] = useState(true);
    const [ifWorkoutRoutine, setIfWorkoutRoutine] = useState(true);
    const [isShiftLoading, setIsShiftLoading] = useState(false);
    const [isShiftUpLoading, setIsShiftUpLoading] = useState(false);
    const [routineState, setRoutineState] = useState({
        title: '',
        description: '',
    });

    useEffect(() => {
        const fetch = async () => {
            setIsDataLoading(true);
            await workoutRoutineStore.fetchRoutines();
            if (workoutRoutineStore.workoutRoutine.length > 0) {
                setIfWorkoutRoutine(true)
                setRoutineState((prev) => ({ ...prev, title: workoutRoutineStore.workoutRoutine[0].title, description: workoutRoutineStore.workoutRoutine[0].description || '' }));
            }
            else {
                setIfWorkoutRoutine(false)

            }
            setIsDataLoading(false);
        }
        fetch()
    }, []);



    const handleSetTitle = (event: any, e: string) => {
        setTitleError(false);
        setIsSaved(false);
        setIsSuccess(false)
        setRoutineState((prev) => ({ ...prev, title: e }));
    }

    const handleSetDescription = (event: any, e: string) => {

        setDescError(false);
        setIsSaved(false);
        setIsSuccess(false)
        setRoutineState((prev) => ({ ...prev, description: e }));
    }

    const handleButtonClick = (id: string) => {
        if (isSaved) {
            return navigate(`/routine/${id}`);
        }
        alert("Please save your routine before adding workouts");
    }
    const handleSubmit = async (e: any) => {
        //check if title is set
        setIsSuccess(false)
        if (routineState.title) {
            //check if description is set
            if (!routineState.description) {
                setDescError(true);
                return
            }
            if (isSaved) {
                setIsSuccess(true)
                return
            }
            setIsLoading(true);
            try {
                if (ifWorkoutRoutine) {
                    //update workoutRoutine
                    await callPut('workoutRoutine', {
                        title: routineState.title,
                        description: routineState.description,
                        id: workoutRoutineStore.workoutRoutine[0].id
                    })
                    // const res = await axios.put(config.backend.url + 'workoutRoutine', {
                    //     //userId: '9d51c2fb-b664-4dfa-a009-4d0e5bf3c991',
                    //     userId: Cookies.get('userId'),
                    //     title: routineState.title,
                    //     description: routineState.description,
                    //     id: workoutRoutineStore.workoutRoutine[0].id
                    // }, {
                    //     headers: {
                    //         "Authorization": `Bearer ${Cookies.get('jwt')}`
                    //     }
                    // })
                } else {
                    await callPost('workoutRoutine', {
                        title: routineState.title,
                        description: routineState.description,
                    })
                    // const res = await axios.post(config.backend.url + 'workoutRoutine', {
                    //     //userId: '9d51c2fb-b664-4dfa-a009-4d0e5bf3c991',
                    //     userId: Cookies.get('userId'),
                    //     title: routineState.title,
                    //     description: routineState.description,
                    // }, {
                    //     headers: {
                    //         "Authorization": `Bearer ${Cookies.get('jwt')}`
                    //     }
                    // })
                }
                //await new Promise((resolve) => setTimeout(resolve, 500))
                setIsLoading(false);
                setIsSaved(true);
                setIsSuccess(true);
                setIfWorkoutRoutine(true)
            }
            catch (err: any) {
                const errorMessage = err?.response?.data?.error?.message
                console.log(errorMessage);
            }
        }
        else {
            setTitleError(true);
        }
    }

    const handleSkipTodaysWorkoutButton = async () => {
        try {
            setIsShiftLoading(true)
            //none null day of the week
            const activeRoutine = workoutRoutineStore.dailyRoutines.filter((routine) => routine.dayOfWeek !== null)
            if (!activeRoutine.length) {
                setIsShiftLoading(false)
                return
            }
            await callPost('routine/skip', {
                routineId: workoutRoutineStore.workoutRoutine[0].id,
                workoutRoutine: activeRoutine
            })
            // const res = await axios.post(config.backend.url + 'routine/skip', {
            //     userId: Cookies.get('userId'),
            //     routineId: workoutRoutineStore.workoutRoutine[0].id,
            //     workoutRoutine: activeRoutine
            // },
            //     {
            //         headers: {
            //             "Authorization": `Bearer ${Cookies.get('jwt')}`
            //         }
            //     }
            // )
        }
        catch (error: any) {
            console.log(error)
        }
        await workoutRoutineStore.fetchRoutines()
        setIsShiftLoading(false)
    }

    const handleShiftWorkoutUpButton = async () => {
        try {
            setIsShiftUpLoading(true)
            //none null day of the week
            const activeRoutine = workoutRoutineStore.dailyRoutines.filter((routine) => routine.dayOfWeek !== null)
            if (!activeRoutine.length) {
                setIsShiftUpLoading(false)
                return
            }
            await callPost('routine/shiftup', {
                routineId: workoutRoutineStore.workoutRoutine[0].id,
                workoutRoutine: activeRoutine
            })
        }
        catch (error: any) {
            console.log(error)
        }
        await workoutRoutineStore.fetchRoutines()
        setIsShiftUpLoading(false)
    }

    const handleExitButton = () => {
        navigate('/')
    }

    return (
        <>
            <Container>
                <Wrapper>
                    {isDataloading ?
                        <div style={{ position: 'absolute', top: '10%', width: '100%', textAlign: 'center' }}>
                            <CircularProgress />
                        </div>
                        :
                        <>
                            <Navbar>
                                {!isMobile && <Button variant="contained" size="large" color="warning" onClick={handleExitButton} sx={{ position: 'absolute', left: '10px', marginTop: '10px' }}>EXIT</Button>}
                                <LoadingButton variant="contained" type="submit" size={"large"} loading={isLoading} loadingPosition="start" startIcon={isSuccess ? <CheckIcon /> : <SaveIcon />} sx={{
                                    position: 'absolute', right: '10px', marginTop: '10px', bgcolor: isSuccess ? 'green' : '#1D9BF0', ':disabled': {
                                        bgcolor: '#ccc'
                                    },
                                    ':hover': {
                                        bgcolor: isSuccess ? 'green' : '#1D9BF0',
                                    }
                                }} onClick={handleSubmit}>SAVE</LoadingButton>
                            </Navbar>
                            <StyledTitleTextField
                                onChange={(e) => handleSetTitle(e, e.target.value)}
                                label="Workout Routine Name"
                                variant="outlined"
                                value={routineState.title}
                                required
                                error={titleError}
                                autoFocus={!ifWorkoutRoutine}
                            />
                            <StyledDescTextField
                                onChange={(e) => handleSetDescription(e, e.target.value)}
                                label="Add a Description to your Workout Routine"
                                variant="filled"
                                value={routineState.description}
                                multiline
                                rows={4}
                                required
                                error={descError}
                            />
                            <RoutineWrapper>

                                <MyRoutineContainer>
                                    <LoadingButton loading={isShiftUpLoading} startIcon={isMobile ? <ArrowUpwardIcon /> : <WestIcon />} loadingPosition="start" variant="contained" size={isMobile ? 'small' : 'large'} color="info" onClick={handleShiftWorkoutUpButton}
                                        sx={{
                                            ':disabled': {
                                                bgcolor: '#ccc'
                                            },
                                            ':hover': {
                                                bgcolor: '#2CA884',
                                            },
                                            width: (isMobile ? '40%' : 'auto'),
                                            backgroundColor: '#2CA884',
                                            flex: isMobile ? '' : 1,

                                        }}
                                    >
                                        Shift Routine
                                    </LoadingButton>


                                    {!isMobile &&
                                        <IconContainer>
                                            <FitnessCenterIcon color="primary" sx={{ fontSize: 30, marginLeft: isMobile ? '50px' : '' }} />
                                            <Language style={{ color: 'white', fontSize: '24px' }}>Weekly Routine</Language>
                                        </IconContainer>}
                                    <LoadingButton loading={isShiftLoading} startIcon={isMobile ? <ArrowDownwardIcon /> : ''} endIcon={isMobile ? '' : <EastIcon />} loadingPosition="start" variant="contained" size={isMobile ? 'small' : 'large'} color="info" onClick={handleSkipTodaysWorkoutButton}
                                        sx={{
                                            ':disabled': {
                                                bgcolor: '#ccc'
                                            },
                                            ':hover': {
                                                bgcolor: '#2CA884',
                                            },
                                            width: (isMobile ? '40%' : 'auto'),
                                            backgroundColor: '#2CA884',
                                            flex: isMobile ? '' : 1,
                                        }}
                                    >
                                        Shift Routine
                                    </LoadingButton>
                                </MyRoutineContainer>

                                <CardWrapper>
                                    <CardStyle >
                                        <CardHeaderStyle title="Sunday" />
                                        <CardContentContainer>
                                            <RoutineTitleStyle variant="h5" align="center">
                                                {GetRoutine(workoutRoutineStore.dailyRoutines, "SUNDAY")?.title || 'Rest Day'}
                                            </RoutineTitleStyle >
                                            <WorkoutButtonWrapper>
                                                <WorkoutButton variant="contained" size={isMobile ? 'medium' : 'large'} onClick={() => handleButtonClick("7")}>{GetRoutine(workoutRoutineStore.dailyRoutines, "SUNDAY") ? 'Edit Routine' : 'New Routine'}</WorkoutButton>
                                            </WorkoutButtonWrapper>
                                        </CardContentContainer>
                                    </CardStyle>
                                    <CardStyle >
                                        <CardHeaderStyle title="Monday" />
                                        <CardContentContainer >
                                            <RoutineTitleStyle variant="h5" align="center">
                                                {GetRoutine(workoutRoutineStore.dailyRoutines, "MONDAY")?.title || 'Rest Day'}
                                            </RoutineTitleStyle>
                                            <WorkoutButtonWrapper>
                                                <WorkoutButton variant="contained" size={isMobile ? 'medium' : 'large'} onClick={() => handleButtonClick("1")}>{GetRoutine(workoutRoutineStore.dailyRoutines, "MONDAY") ? 'Edit Routine' : 'New Routine'}</WorkoutButton>
                                            </WorkoutButtonWrapper>
                                        </CardContentContainer>
                                    </CardStyle>
                                    <CardStyle >
                                        <CardHeaderStyle title="Tuesday" />
                                        <CardContentContainer>
                                            <RoutineTitleStyle variant="h5" align="center">
                                                {GetRoutine(workoutRoutineStore.dailyRoutines, "TUESDAY")?.title || 'Rest Day'}
                                            </RoutineTitleStyle >

                                            <WorkoutButtonWrapper>
                                                <WorkoutButton variant="contained" size={isMobile ? 'medium' : 'large'} onClick={() => handleButtonClick("2")}>{GetRoutine(workoutRoutineStore.dailyRoutines, "TUESDAY") ? 'Edit Routine' : 'New Routine'}</WorkoutButton>
                                            </WorkoutButtonWrapper>
                                        </CardContentContainer>
                                    </CardStyle>
                                    <CardStyle >
                                        <CardHeaderStyle title="Wednesday" />
                                        <CardContentContainer>
                                            <RoutineTitleStyle variant="h5" align="center">
                                                {GetRoutine(workoutRoutineStore.dailyRoutines, "WEDNESDAY")?.title || 'Rest Day'}
                                            </RoutineTitleStyle >

                                            <WorkoutButtonWrapper>
                                                <WorkoutButton variant="contained" size={isMobile ? 'medium' : 'large'} onClick={() => handleButtonClick("3")}>{GetRoutine(workoutRoutineStore.dailyRoutines, "WEDNESDAY") ? 'Edit Routine' : 'New Routine'}</WorkoutButton>
                                            </WorkoutButtonWrapper>
                                        </CardContentContainer>
                                    </CardStyle>
                                    <CardStyle >
                                        <CardHeaderStyle title="Thursday" />
                                        <CardContentContainer>
                                            <RoutineTitleStyle variant="h5" align="center">
                                                {GetRoutine(workoutRoutineStore.dailyRoutines, "THURSDAY")?.title || 'Rest Day'}
                                            </RoutineTitleStyle >

                                            <WorkoutButtonWrapper>
                                                <WorkoutButton variant="contained" size={isMobile ? 'medium' : 'large'} onClick={() => handleButtonClick("4")}>{GetRoutine(workoutRoutineStore.dailyRoutines, "THURSDAY") ? 'Edit Routine' : 'New Routine'}</WorkoutButton>
                                            </WorkoutButtonWrapper>
                                        </CardContentContainer>
                                    </CardStyle>
                                    <CardStyle >
                                        <CardHeaderStyle title="Friday" />
                                        <CardContentContainer>
                                            <RoutineTitleStyle variant="h5" align="center">
                                                {GetRoutine(workoutRoutineStore.dailyRoutines, "FRIDAY")?.title || 'Rest Day'}
                                            </RoutineTitleStyle >
                                            <WorkoutButtonWrapper>
                                                <WorkoutButton variant="contained" size={isMobile ? 'medium' : 'large'} onClick={() => handleButtonClick("5")}>{GetRoutine(workoutRoutineStore.dailyRoutines, "FRIDAY") ? 'Edit Routine' : 'New Routine'}</WorkoutButton>
                                            </WorkoutButtonWrapper>
                                        </CardContentContainer>
                                    </CardStyle>
                                    <CardStyle >
                                        <CardHeaderStyle title="Saturday" />
                                        <CardContentContainer>
                                            <RoutineTitleStyle variant="h5" align="center">
                                                {GetRoutine(workoutRoutineStore.dailyRoutines, "SATURDAY")?.title || 'Rest Day'}
                                            </RoutineTitleStyle >

                                            <WorkoutButtonWrapper>
                                                <WorkoutButton variant="contained" size={isMobile ? 'medium' : 'large'} onClick={() => handleButtonClick("6")}>{GetRoutine(workoutRoutineStore.dailyRoutines, "SATURDAY") ? 'Edit Routine' : 'New Routine'}</WorkoutButton>
                                            </WorkoutButtonWrapper>
                                        </CardContentContainer>
                                    </CardStyle>

                                </CardWrapper>


                                {/* 
                        <CardWrapper>
                            <CardStyle >
                                <CardHeaderStyle title="Monday" />
                                <CardContent >
                                    <RoutineTitleStyle variant="h5" align="center">
                                        {GetRoutine(workoutRoutineStore.dailyRoutines, "MONDAY")?.title || 'Rest Day'}
                                    </RoutineTitleStyle>
                                    <WorkoutButtonWrapper>
                                        <WorkoutButton variant="contained" size='large' onClick={() => handleButtonClick("1")}>Create Routine</WorkoutButton>
                                    </WorkoutButtonWrapper>
                                </CardContent>
                            </CardStyle>
                            <CardStyle >
                                <CardHeaderStyle title="Tuesday" />
                                <CardContent>
                                    <RoutineTitleStyle variant="h5" align="center">
                                        {GetRoutine(workoutRoutineStore.dailyRoutines, "TUESDAY")?.title || 'Rest Day'}
                                    </RoutineTitleStyle >

                                    <WorkoutButtonWrapper>
                                        <WorkoutButton variant="contained" size='large' onClick={() => handleButtonClick("2")}>Create Routine</WorkoutButton>
                                    </WorkoutButtonWrapper>
                                </CardContent>
                            </CardStyle>
                            <CardStyle >
                                <CardHeaderStyle title="Wednesday" />
                                <CardContent>
                                    <RoutineTitleStyle variant="h5" align="center">
                                        {GetRoutine(workoutRoutineStore.dailyRoutines, "WEDNESDAY")?.title || 'Rest Day'}
                                    </RoutineTitleStyle >

                                    <WorkoutButtonWrapper>
                                        <WorkoutButton variant="contained" size='large' onClick={() => handleButtonClick("3")}>Create Routine</WorkoutButton>
                                    </WorkoutButtonWrapper>
                                </CardContent>
                            </CardStyle>
                            <CardStyle >
                                <CardHeaderStyle title="Thursday" />
                                <CardContent>
                                    <RoutineTitleStyle variant="h5" align="center">
                                        {GetRoutine(workoutRoutineStore.dailyRoutines, "THURSDAY")?.title || 'Rest Day'}
                                    </RoutineTitleStyle >

                                    <WorkoutButtonWrapper>
                                        <WorkoutButton variant="contained" size='large' onClick={() => handleButtonClick("4")}>Create Routine</WorkoutButton>
                                    </WorkoutButtonWrapper>
                                </CardContent>
                            </CardStyle>
                            <CardStyle >
                                <CardHeaderStyle title="Friday" />
                                <CardContent>
                                    <RoutineTitleStyle variant="h5" align="center">
                                        {GetRoutine(workoutRoutineStore.dailyRoutines, "FRIDAY")?.title || 'Rest Day'}
                                    </RoutineTitleStyle >
                                    <WorkoutButtonWrapper>
                                        <WorkoutButton variant="contained" size='large' onClick={() => handleButtonClick("5")}>Create Routine</WorkoutButton>
                                    </WorkoutButtonWrapper>
                                </CardContent>
                            </CardStyle>
                            <CardStyle >
                                <CardHeaderStyle title="Saturday" />
                                <CardContent>
                                    <RoutineTitleStyle variant="h5" align="center">
                                        {GetRoutine(workoutRoutineStore.dailyRoutines, "SATURDAY")?.title || 'Rest Day'}
                                    </RoutineTitleStyle >

                                    <WorkoutButtonWrapper>
                                        <WorkoutButton variant="contained" size='large' onClick={() => handleButtonClick("6")}>Create Routine</WorkoutButton>
                                    </WorkoutButtonWrapper>
                                </CardContent>
                            </CardStyle>
                            <CardStyle >
                                <CardHeaderStyle title="Sunday" />
                                <CardContent>
                                    <RoutineTitleStyle variant="h5" align="center">
                                        {GetRoutine(workoutRoutineStore.dailyRoutines, "SUNDAY")?.title || 'Rest Day'}
                                    </RoutineTitleStyle >
                                    <WorkoutButtonWrapper>
                                        <WorkoutButton variant="contained" size='large' onClick={() => handleButtonClick("7")}>Create Routine</WorkoutButton>
                                    </WorkoutButtonWrapper>
                                </CardContent>
                            </CardStyle>
                        </CardWrapper> */}
                                {/* {!ifWorkoutRoutine && <NoWorkoutRoutineMessage>
                            <Typography variant='h3'>
                                You Can Start Creating Your Weekly Routine By
                                <br />
                                Entering a Workout Routine Name and Saving It!
                            </Typography>
                        </NoWorkoutRoutineMessage>} */}
                            </RoutineWrapper>
                        </>}
                </Wrapper>
            </Container>
        </>
    )
}

export default observer(WorkoutRoutineComponent)

const Container = styled.div`
  /* width: 100%;
  height: 100%;
  background: #192633;
  position: fixed;
  display: flex;
  justify-content: center;
  overflow: auto; */
  ${baseContainerStyles}
  /* padding-bottom: ${isMobile ? (Capacitor.getPlatform() === 'ios' ? 'calc(env(safe-area-inset-top) + 76px)' : '112px') : ''}; */
`

const Navbar = styled.div`
  height: 60px;
  width: 100%;
  position: relative;
  display: flex;
`

const Wrapper = styled.div`
 width: ${isMobile ? '100%' : '85%'};
  background-color: #15202b;
  position: relative;
  overflow: auto;
  scrollbar-width: none;
  /* padding-bottom: ${isMobile ? '100px' : ''}; */
  padding-bottom: ${Capacitor.isNativePlatform() ? 'calc(200px + env(safe-area-inset-bottom))' : (isMobile ? '140px' : '')};
`
const StyledTitleTextField = styled(TextField)({
    marginTop: '2%',
    width: '80%',
    marginLeft: '10%',
    font: 'bold',
    '& label.Mui-focused': {
        color: 'lightgrey',
    },
    '& .MuiOutlinedInput-input': {
        color: 'white',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#808080',
        },
        '&:hover fieldset': {
            borderColor: 'lightgrey',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'lightgrey',
        },
    },
    '& .MuiInputLabel-root': {
        color: '#7C8A97',
    },
})

const StyledDescTextField = styled(TextField)({
    marginLeft: '10%',
    width: '80%',
    '& label.Mui-focused': {
        color: 'lightgrey',
    },
    '& .MuiFilledInput-input': {
        color: 'white',
    },
    '& .MuiFilledInput-root': {
        '&:before': {
            borderBottomColor: '#808080',
        },
        '&:hover:before': {
            borderBottomColor: '#bdbdbd',
        },
        '&.Mui-focused:after': {
            borderBottomColor: 'lightgrey',
        },
    },
    '& .MuiInputLabel-root': {
        color: '#7C8A97',
    },
})


const RoutineWrapper = styled.div`
  width: 100%;
  height: 800px;
  position: relative;
  z-index: 10;
  border-radius: 10px;
  align-items: center;
  margin-bottom: ${isMobile ? '450px' : '180px'};
`
//navbar
const MyRoutineContainer = styled.div`
  height: 55px;
  background-color: #15202b;
  /* padding: 10px 20px; */
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? 'space-between' : 'center'};
  border-radius: 5px;
  margin-left: ${isMobile ? '5%' : ''};
  margin-right: ${isMobile ? '5%' : ''};
  margin-bottom: ${isMobile ? '2%' : ''};
`

const Language = styled.span`
  font-style: 14px;
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  background-color: transparent;
  border: none;
  flex: 1;
  justify-content: center;
`

const CardWrapper = styled.div`
  display: ${isMobile ? '' : 'flex'};
  height: 100%;
  width: 100%;
`

const CardStyle = styled(Card)`
  width: 90%;  
  position: relative;
  background-color: #cacaca;
  margin-left: ${isMobile ? '5%' : ''};
  margin-bottom: ${isMobile ? '2%' : ''};
`

const CardHeaderStyle = styled(CardHeader)`
  background-color: #e9e9e9;
  color: black  ;
  text-align: center;
  .MuiCardHeader-title {
      font-size: 20px;
    }
`

const WorkoutButtonWrapper = styled.div`
    justify-content: center;
    display: flex;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    margin-bottom: ${isMobile ? '' : '50px'};
`

const WorkoutButton = styled(Button)`
    
`

const RoutineTitleStyle = styled(Typography)`   
    margin-top: ${isMobile ? '10px' : '200px'};
    margin-bottom: ${isMobile ? '20px' : ''};
`

const CardContentContainer = styled(CardContent)({
    display: 'flex',
    flexDirection: 'column',
})


const NoWorkoutRoutineMessage = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  top: 56.9%;
  left: 50%;
  background-color: rgba(0, 0, 0, 0.63);
`