import { useEffect, useState } from "react";
import { styled, BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SearchIcon from '@mui/icons-material/Search';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import { useNavigate } from "react-router-dom";
import mobileSelectedStore from "../../store/mobileSelectedStore";
import { observer } from "mobx-react-lite";
import { Capacitor } from "@capacitor/core";

const StyledNavAction = styled(BottomNavigationAction)({
    color: 'grey',
    "&.Mui-focusVisible": {
        outline: "none",
    },
    "&:focus": {
        outline: "none",
    },
    '&.Mui-selected': {
        color: 'white'
    }

})

const MobileBottomBar = observer(() => {
    const navigate = useNavigate()

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        mobileSelectedStore.setCurrentlySelected(newValue)
        if (newValue === 0) {
            navigate('/routine')
        } else if (newValue === 1) {
            navigate('/')
        } else if (newValue === 2) {
            navigate('/progress')
        }
    };

    return (
        <>
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: '9999', bgcolor: '#192633', borderRadius: 0 }} elevation={3}>
                <BottomNavigation showLabels value={mobileSelectedStore.currentlySelected} onChange={handleChange} sx={{ bgcolor: '#192633', position: 'relative', marginBottom: Capacitor.getPlatform() === 'ios' ? 'env(safe-area-inset-bottom)' : '10px' }}>
                    <StyledNavAction icon={<FitnessCenterIcon fontSize="small" />} label="Routine" />
                    <StyledNavAction icon={<CalendarMonthIcon fontSize="small" />} label="Calendar" />
                    <StyledNavAction icon={<SearchIcon fontSize="small" />} label="Progress" />
                </BottomNavigation>
            </Paper >
        </>
    )
})

export default MobileBottomBar