import { User } from "firebase/auth";
import { auth } from "../libs/firebase";
import { FirebaseAuthentication } from '@capacitor-firebase/authentication'
import { Capacitor } from "@capacitor/core";

const waitForAuthInit = async () => {
    let unsubscribe;
    await new Promise<void>((resolve) => {
        unsubscribe = auth.onAuthStateChanged((_) => resolve());
    });
    (await unsubscribe!)();
}

const getCurrentUser = async (): Promise<any> => {
    try {
        if (Capacitor.isNativePlatform()) {
            return await FirebaseAuthentication.getCurrentUser()
        } else {
            await waitForAuthInit()
            return await auth.currentUser
        }
    } catch (err: any) {
        return null
    }
}


export default getCurrentUser