import { makeAutoObservable } from 'mobx';

class MobileSelectedStore {
    currentlySelected: number = 1;
    fromProgressPage: boolean = false;

    constructor() {
        makeAutoObservable(this);
        // get the route from the url and set the currently selected
        const url = window.location.href.split('/').pop();
        if (url === 'routine') {
            this.currentlySelected = 0
        }
        else if (url === '') {
            this.currentlySelected = 1
        }
        else if (url === 'progress') {
            this.currentlySelected = 2
        }

    }

    setCurrentlySelected(newValue: number) {
        this.currentlySelected = newValue;
    }
    setFromProgressPage(newValue: boolean) {
        this.fromProgressPage = newValue;
    }
}

const mobileSelectedStore = new MobileSelectedStore();
export default mobileSelectedStore;
