import React from 'react';
import IconButton from '@mui/material/IconButton';
import styled from '@emotion/styled';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { MenuItem, Menu, Button } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseIcon from '@mui/icons-material/Close';
const StyledMenu = styled(Menu)({
    '& .MuiPaper-root': {
        borderRadius: '6px',
        backgroundColor: '#232a31',
        color: 'white'
    }
})


const ExerciseMenuButtonComponent = ({ exercise, index, handleRemoveExercise }: any) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorEl);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>

            <MoreVertIcon style={{ position: 'absolute', right: '10px', top: '10px', color: 'white', fontSize: '30px' }}
                aria-controls={openMenu ? 'basic-menu' : undefined}
                aria-expanded={openMenu ? 'true' : undefined}
                aria-haspopup="true"
                onClick={(e) => handleClick(e)}
            />
            <StyledMenu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >

                <MenuItem onClick={handleClose}>
                    <Button type="button" size="small" startIcon={<DeleteForeverIcon sx={{ color: 'red' }} />} sx={{
                        color: 'white',
                        textTransform: 'none',
                        fontSize: '16px',
                    }} onClick={() => (handleRemoveExercise(index))} >Delete Exercise</Button>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Button size='small' startIcon={<CloseIcon />} style={{
                        color: 'white',
                        textTransform: 'none',
                        fontSize: '16px',
                    }}>
                        Close
                    </Button>
                </MenuItem>

            </StyledMenu>
        </>
    );
};

export default ExerciseMenuButtonComponent;
