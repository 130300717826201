
import { isMobile } from "react-device-detect"
import styled from "@emotion/styled"
import { Typography } from '@mui/material'
import { Capacitor } from '@capacitor/core';

const LandingPageContainer = styled.div`
    /* background-color: #192633; */
    display: flex;
    flex-direction: ${isMobile ? 'column' : 'row'};
    width: 100vw;
    height: 100vh;
`

const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1.5;
  background-color: #141d27;
`

const LeftSection = styled.div`
  display: ${isMobile ? 'none' : 'flex'};
  justify-content: center;
  align-items: center;
  flex: 0.5;
  background-color: #192633; 
`


const StyledLogoContainer = styled.div`
    display: flex; 
    flex-direction: row; 
    align-items: center; 
    justify-content: center; 
    height: 200px; 
    margin-top: ${isMobile && window.innerHeight > 700 ? '15%' : isMobile ? '1%' : '7%'};   
`

const StyledMobileLogoContainer = styled.div`
    display: ${isMobile ? 'none' : 'none'}; 
    position: absolute;
    top: 0;
    height: 200px; 
    margin-top: ${isMobile && window.innerHeight > 700 ? (Capacitor.getPlatform() === 'ios' ? 'env(safe-area-inset-top)' : '5%') : '1%'};
`

const BaseTypography = styled(Typography)`
    margin-top: 10px;


`


const AthanReminderPrivacyPolicyPage = () => {
    return (
        <>
            <LandingPageContainer>
                <LeftSection>
                    <StyledLogoContainer>
                        <Typography variant='h2' color='white' fontSize={'120px'}>Athan Reminder</Typography>
                    </StyledLogoContainer>
                </LeftSection>
                <RightSection>
                    <StyledMobileLogoContainer>
                        <Typography variant='h2' color='white' fontSize={'120px'}>Athan Reminder</Typography>
                    </StyledMobileLogoContainer>
                    <Typography color='lightgrey' variant={isMobile ? 'h3' : 'h1'}>Privacy Policy</Typography>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px', marginBottom: '30px' }}>
                        <Typography variant='h5' color='lightgrey' fontSize={(isMobile && window.innerWidth < 380) ? '18px' : isMobile ? '20px ' : '30px'}>
                            <Typography variant="h4" sx={{ marginLeft: isMobile ? '20px' : '' }}>1. Contact Information</Typography>
                            <BaseTypography variant="body1" sx={{ marginLeft: isMobile ? '40px' : '' }}>
                                athanreminderhelp@gmail.com
                            </BaseTypography>
                            <BaseTypography variant="h4" sx={{ marginLeft: isMobile ? '20px' : '' }}>2. Information We Collect</BaseTypography>
                            <BaseTypography variant="body1" sx={{ marginLeft: isMobile ? '40px' : '' }}>
                                We collect the following data:
                                <BaseTypography variant="body1" sx={{ marginLeft: '20px' }}>
                                    - None of your Personal Information is stored on our end <br />
                                </BaseTypography>
                                <Typography variant="body1" sx={{ marginLeft: '20px', marginBottom: '10px' }}>
                                    - Your Location is stored only on your device<br />
                                </Typography>
                                For any questions or concerns regarding this Privacy Policy, please contact us via the information provided above.
                            </BaseTypography>
                        </Typography>
                    </div>
                </RightSection>
            </LandingPageContainer>
        </>
    )
}

export default AthanReminderPrivacyPolicyPage