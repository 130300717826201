import React from 'react'
import Navbar from '../components/Navbar';
import CalenderComponent from '../components/calender/CalenderComponent';
import MobileBottomBar from '../components/Mobile/MobileBottomBar';
import { isMobile } from 'react-device-detect';
import { Capacitor } from '@capacitor/core';

const HomePage = (props: any) => {
  return (
    <>
      <Navbar title={'Swole'} />
      <CalenderComponent />
      {isMobile && <MobileBottomBar />}
      {/* {Capacitor.isNativePlatform() && <MobileBottomBar currentSelected={props.currentSelected} setCurrentSelected={props.setCurrentSelected} />} */}
    </>
  )
}

export default HomePage;  