import { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../config';
import getCurrentUser from "../utils/GetCurrentUser";
import Cookies from 'js-cookie';
import { isMobile } from 'react-device-detect';
import { Preferences } from '@capacitor/preferences';
import { Capacitor } from '@capacitor/core';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { getToken, getUserId } from '../utils/mobileStorage';
/*
    in component: const { data, loading } = useGet<type expected from response>('/hello')
*/

export async function checkCookies() {
    if (Capacitor.isNativePlatform()) {
        const user = await getCurrentUser()
        const userId = user?.user.uid
        const token = await FirebaseAuthentication.getIdToken({ forceRefresh: true })
        if (token.token && userId) {
            await Preferences.set({
                key: 'userId',
                value: userId,
            });
            await Preferences.set({
                key: 'jwt',
                value: token.token,
            });
        }
    }
    else if (Cookies.get('jwt') === undefined || Cookies.get('userId') === undefined) {
        const user = await getCurrentUser()
        const userId = user?.uid
        const token = await user?.getIdToken(true)
        if (token && userId) {
            Cookies.set('jwt', token, { expires: (1 / 24), secure: config.cookieSecure });
            Cookies.set('userId', userId, { expires: (1 / 24), secure: config.cookieSecure });
        }
    }
}


function useGet<T>(url: string) {
    const [loading, setLoading] = useState(true);
    const [reponseData, setReponseData] = useState<T>();

    const get = async () => {
        const res = await axios.get(config.backend.url + url);
        setReponseData(res.data as T);
        setLoading(false);
    };

    useEffect(() => {
        get();
    }, []);

    return {
        loading,
        reponseData,
    };
}

export function UsePost<T>() {
    const [loading, setLoading] = useState(true);
    const [postData, setPostData] = useState<any>();

    const callPost = async (url: string, data: object) => {
        await checkCookies()

        const dataWithUserId = {
            ...data,
            userId: await getUserId()
        }
        try {
            const res = await axios.post(config.backend.url + url, dataWithUserId, {
                headers: {
                    "Authorization": await getToken()
                }
            })
            setPostData(res.data.data);
        } catch (err) {
            console.log(err)
        }
        setLoading(false);
    };

    return {
        postLoading: loading,
        postData,
        callPost
    };
}

export function UsePut<T>() {
    const [loading, setLoading] = useState(true);
    const [reponseData, setReponseData] = useState<any>();
    const callPut = async (url: string, data: object) => {
        await checkCookies()

        const dataWithUserId = {
            ...data,
            userId: await getUserId()
        }

        try {
            const res = await axios.put(config.backend.url + url, dataWithUserId, {
                headers: {
                    "Authorization": await getToken()
                }
            });
            if (res.data) {
                setReponseData(res.data);
            }

        } catch (err: any) {
            console.log(err)
        }
        setLoading(false);

    }

    return {
        putLoading: loading,
        putResponse: reponseData,
        callPut
    }
}

function useDelete<T>(url: string, data: object) {
    const [loading, setLoading] = useState(true);
    const [reponseData, setReponseData] = useState<T>();

    const callDelete = async () => {
        const res = await axios.post(config.backend.url + url, data);
        setReponseData(res.data as T);
        setLoading(false);
    };

    useEffect(() => {
        callDelete();
    }, []);

    return {
        loading,
        reponseData,
    };
}
