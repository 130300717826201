import Cookies from "js-cookie";
import { auth } from "../libs/firebase";
import config from "../config";
import getCurrentUser from "./GetCurrentUser";
import { Capacitor } from "@capacitor/core";
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import { removeAllPref } from "./mobileStorage";
import mobileSelectedStore from "../store/mobileSelectedStore";
import { useNavigate } from "react-router-dom";

const ErrorConfig = async (error: any) => {
    const navigate = useNavigate()
    if (error.response && error.response.status === 401) {
        Cookies.remove('jwt');
        Cookies.remove('userId');
        try {
            const user = await getCurrentUser()
            let token: string
            if (Capacitor.isNativePlatform()) {
                const result = await FirebaseAuthentication.getIdToken()
                token = result?.token
            } else {
                token = await user?.getIdToken();
            }
            if (token && auth.currentUser) {
                Cookies.set('jwt', token, { expires: 30, secure: config.cookieSecure });
                Cookies.set('userId', auth.currentUser.uid, { expires: 30, secure: config.cookieSecure });
                window.location.reload()
            }
        } catch (err) {
            // await auth.signOut();
            await removeAllPref()
            sessionStorage.clear()
            localStorage.removeItem('routineState')
            mobileSelectedStore.setCurrentlySelected(1)
            await FirebaseAuthentication.signOut()
            console.log(err)
            navigate('/login')
        }

    } else {
        // Handle other errors here
        console.error(error);

    }
}

export default ErrorConfig;
