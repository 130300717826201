
import { isMobile } from "react-device-detect"
import MobileBottomBar from "../components/Mobile/MobileBottomBar"
import Navbar from "../components/Navbar"
import WorkoutRoutineComponent from "../components/routines/WorkoutRoutineComponent"

const WorkoutRoutinePage = (props: any) => {
    return (
        <>
            <Navbar title={'Weekly Routine Split'} />
            <WorkoutRoutineComponent />
            {isMobile && <MobileBottomBar />}
        </>
    )
}

export default WorkoutRoutinePage