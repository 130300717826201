import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { useState, ChangeEvent } from 'react';
import {
    TextField,
    Stack,
    Button,
    Divider,
    Card,
    Typography,
    InputAdornment,
    Menu,
    Autocomplete,
    Box,
    CircularProgress
} from '@mui/material';
import Exercise from '../../../types/Exercise';
import Routine from '../../../types/Routine';
import axios from 'axios';
import config from '../../../config';
import { useLocation, useNavigate } from 'react-router-dom';
import LoggedRoutine from '../../../types/Logged/LoggedRoutine';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import CheckIcon from '@mui/icons-material/Check';
import DailyRoutine from '../../../types/DailyRoutine';
import workoutRoutineStore from '../../../store/workoutRoutineStore';
import { isMobile } from 'react-device-detect';
import { set } from 'mobx';
import Cookies from 'js-cookie';
import { checkCookies } from '../../../libs/api';
import { getRoutineStatePref, getToken, getUserId, setRoutineStatePref } from '../../../utils/mobileStorage';
import { baseContainerStyles } from '../../styled/baseStyles';
import ExerciseMenuButtonComponent from '../../styled/ExerciseMenuButtonComponent';
import { debounce } from 'lodash';
import { Capacitor } from '@capacitor/core';

interface Props {
    startDate: string,
    routineState: LoggedRoutine,
}

function LoggedWorkout() {
    const navigate = useNavigate();
    const location = useLocation();
    const data = location.state as Props;
    const [isLoading, setIsLoading] = useState(false);
    const [isDataloading, setIsDataLoading] = useState(true);
    const [titleError, setTitleError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isSaved, setIsSaved] = useState(true);
    const [isUpdate, setIsUpdate] = useState(false);
    const [loggedWorkoutId, setLoggedWorkoutId] = useState<number>();
    const [routineState, setRoutineState] = useState<Routine>({
        title: '',
        description: '',
        currentId: 0,
        exercises: [],
    });
    const moment = require('moment');
    const storedLoggedRoutine = data.routineState
    useEffect(() => {
        const getRoutineState = async () => {
            const routineStatePref = await getRoutineStatePref()
            const storageLoggedRoutine = JSON.parse(routineStatePref || '')
            if (storageLoggedRoutine.futureDate) {
                setIsUpdate(true)
            } else {
                //remove id since its a dailyroutine id
                delete storageLoggedRoutine.id
            }
            setRoutineState(storageLoggedRoutine)
        }
        //If there is a routine in local storage use that else if
        if (localStorage.getItem('routineState')) {
            setIsDataLoading(true)
            let storageLoggedRoutine
            if (Capacitor.isNativePlatform()) {
                getRoutineState()
            } else {
                storageLoggedRoutine = JSON.parse(localStorage.getItem('routineState')!);
            }


            if (storageLoggedRoutine.futureDate) {
                setIsUpdate(true)
            } else {
                //remove id since its a dailyroutine id
                delete storageLoggedRoutine.id
            }
            //update Routine state 
            setRoutineState(storageLoggedRoutine)
            setIsDataLoading(false)
        }
        //on submits clear the storage if it exists
        else if (storedLoggedRoutine) {
            setIsDataLoading(true)
            if (storedLoggedRoutine.futureDate) {
                setIsUpdate(true)
            } else {
                //remove id since its a dailyroutine id
                delete storedLoggedRoutine.id
            }
            setRoutineState((prevState) => ({
                ...prevState,
                title: storedLoggedRoutine?.title || '',
                description: storedLoggedRoutine?.description || '',
                currentId: storedLoggedRoutine?.exercises.length || prevState.currentId,
                exercises: storedLoggedRoutine?.exercises
                    ? storedLoggedRoutine.exercises.map((exercise, index) => ({
                        title: exercise.title,
                        exerciseId: index,
                        repsWeight: exercise.repsWeight.map((repsWeight) => ({
                            reps: repsWeight.reps,
                            weight: repsWeight.weight,
                        })),
                    }))
                    : prevState.exercises,
            }));
            setIsDataLoading(false)
        }
        setIsDataLoading(false)
    }, [])

    useEffect(() => {
        //reset submit button
        setIsSuccess(false)
        setIsSaved(false)
        const debouncedSave = debounce(async () => {
            if (Capacitor.isNativePlatform()) {
                await setRoutineStatePref(JSON.stringify(routineState))
            } else {
                localStorage.setItem('routineState', JSON.stringify(routineState));
            }
        }, 2000); // delay in milliseconds

        debouncedSave();

        // Cleanup function to cancel the debounced save if the component unmounts while the save is still pending
        return () => {
            debouncedSave.cancel();
        };
    }, [routineState])

    const handleSubmit = async (e: any) => {
        setIsSuccess(false)
        //title isnt blank
        if (routineState.title) {
            if (isSaved) {
                setIsSuccess(true)
                return
            }
            setIsLoading(true);
            try {
                // TODO: add id for routine on update so we can delete old entry before creating new one on update
                await checkCookies()
                const newData: LoggedRoutine = {
                    userId: await getUserId() || '',
                    title: routineState.title,
                    description: routineState.description,
                    exercises: routineState.exercises,
                    futureDate: moment(data.startDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                }
                if (isUpdate) {
                    newData.id = storedLoggedRoutine?.id || loggedWorkoutId
                    const res = await axios.put(config.backend.url + 'loggedWorkout', newData, {
                        headers: {
                            "Authorization": await getToken()
                        }
                    })
                }
                else {
                    const res = await axios.post(config.backend.url + 'loggedWorkout', newData, {
                        headers: {
                            "Authorization": await getToken()
                        }
                    })
                    setIsUpdate(true)
                    setLoggedWorkoutId(res.data.data.id)
                }
                setIsSuccess(true);
                setIsLoading(false);
            }
            catch (e: any) {
                const errorMessage = e?.response?.data?.error?.message
                console.log(errorMessage)
            }
        }
        else {
            setTitleError(true);
        }

        // check if data.startDate is a futureDate to set futureDate
    }
    // add exercise count for each time add exercise button is clicked
    const handleAddExercise = () => {
        setRoutineState((prevState) => ({
            ...prevState,
            currentId: prevState.currentId + 1,
            exercises: [
                ...prevState.exercises,
                {
                    exerciseId: prevState.currentId,
                    title: '',
                    repsWeight: [{
                        reps: '',
                        weight: '',
                    }]
                }
            ]
        }))
    }
    //add new set to exercise
    const handleAddSet = (newExercise: Exercise) => {
        setRoutineState((prevState) => ({
            ...prevState,
            exercises: prevState.exercises.map((ex: Exercise) => {
                if (newExercise.exerciseId === ex.exerciseId) {
                    return {
                        ...ex,
                        repsWeight: [
                            ...ex.repsWeight,
                            {
                                reps: '',
                                weight: '',
                            }
                        ]
                    }
                }
                return ex;
            })
        }));
    }
    const handleSetTitle = (event: React.SyntheticEvent<Element, Event>, e: string) => {
        if (event === null) {
            return
        }
        setTitleError(false);
        setRoutineState((prevState) => ({ ...prevState, title: e }));
    }

    const handleSetDescription = (e: string) => {
        setRoutineState((prevState) => ({ ...prevState, description: e }));
    }

    const handleRepsTextInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, exercise: Exercise, index: number) => {
        let firstDigit = (e.target.value).toString().charAt(0);
        if (Number(e.target.value) > 999) {
            return;
        }
        else if (firstDigit === '0') {
            e.target.value = '0';
        }
        else if (e.target.value.length === 0) {
            e.target.value = '';
        }

        setRoutineState((prevState) => ({
            ...prevState,
            exercises: prevState.exercises.map((ex: Exercise) => {
                if (exercise.exerciseId !== ex.exerciseId) {
                    return ex;
                }
                return {
                    ...ex,
                    repsWeight: ex.repsWeight.map((rw: any, i: number) => {
                        if (i === index) {
                            return {
                                ...rw,
                                reps: e.target.value,
                            };
                        }
                        return rw;
                    }),
                };
            }),
        }));
    }

    const handleWeightTextInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, exercise: Exercise, index: number) => {
        let firstDigit = (e.target.value).toString().charAt(0);
        if (Number(e.target.value) > 999.9) {
            return;
        }
        else if (firstDigit === '0') {
            e.target.value = '0';
        }
        else if ((e.target.value.includes('.'))) {
            let parts = (e.target.value).split('.');
            if (parts.length > 1) {
                e.target.value = parts[0] + '.' + parts[1].substring(0, 1);
            }
        }
        setRoutineState((prevState) => ({
            ...prevState,
            exercises: prevState.exercises.map((ex: Exercise) => {
                if (exercise.exerciseId !== ex.exerciseId) {
                    return ex;
                }
                return {
                    ...ex,
                    repsWeight: ex.repsWeight.map((rw: any, i: number) => {
                        if (i === index) {
                            return {
                                ...rw,
                                weight: e.target.value,
                            }
                        }
                        return rw;
                    }),
                };
            }),
        }));
    }

    const handleExerciseNameChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, newExercise: Exercise) => {
        setRoutineState((prevState) => ({
            ...prevState,
            exercises: prevState.exercises.map((exer: Exercise) => {
                if (newExercise.exerciseId === exer.exerciseId) {
                    return {
                        ...exer,
                        title: e.target.value,
                    }
                }
                return exer
            }),
        }))
    }

    const handleRemoveSet = (exercise: Exercise, index: number) => {
        setRoutineState((prevState) => ({
            ...prevState,
            exercises: prevState.exercises.map((ex: Exercise) => {
                if (exercise.exerciseId === ex.exerciseId) {
                    return {
                        ...ex,
                        repsWeight: ex.repsWeight.filter((rw: any, i: number) => i !== index),
                    }
                }
                return ex;
            })
        }))
    }

    const handleRemoveExercise = (index: number) => {
        setRoutineState((prevState) => ({
            ...prevState,
            exercises: prevState.exercises.filter((ex: Exercise, i: number) => i !== index)
        }))

    }
    const handleExitButton = () => {
        navigate(-1)
    }


    const handleSelectRoutine = (event: React.SyntheticEvent<Element, Event>, selectedRoutine: DailyRoutine | string | null) => {
        if (selectedRoutine === null || typeof selectedRoutine === 'string') {
            return;
        }
        setRoutineState((prevState) => ({
            ...prevState,
            id: selectedRoutine?.id,
            title: selectedRoutine?.title || '',
            dayOfWeek: selectedRoutine?.dayOfWeek,
            description: selectedRoutine?.description || '',
            currentId: selectedRoutine?.exercises.length || prevState.currentId,
            exercises: selectedRoutine?.exercises
                ? selectedRoutine.exercises.map((exercise: any, index: number) => ({
                    title: exercise.title,
                    exerciseId: index,
                    repsWeight: exercise.repsWeight.map((repsWeight: any) => ({
                        reps: repsWeight.reps,
                        weight: repsWeight.weight,
                    })),
                }))
                : prevState.exercises,
        }));
    };



    return (
        <>
            <Container>
                <Wrapper>
                    {isDataloading ?
                        <div style={{ position: 'absolute', top: '10%', width: '100%', textAlign: 'center' }}>
                            <CircularProgress />
                        </div>
                        :
                        <>

                            <Navbar>
                                {!isMobile && <Button variant="contained" size="large" color="warning" onClick={handleExitButton} sx={{ position: 'absolute', left: '10px', marginTop: '10px' }}>EXIT</Button>}
                                <LoadingButton variant="contained" type="submit" size="large" loading={isLoading} loadingPosition="start" startIcon={isSuccess ? <CheckIcon /> : <SaveIcon />} sx={{
                                    position: 'absolute', right: '10px', marginTop: '10px', bgcolor: isSuccess ? 'green' : '#1D9BF0', ':disabled': {
                                        bgcolor: '#ccc'
                                    },
                                    ':hover': {
                                        bgcolor: isSuccess ? 'green' : '#1D9BF0',
                                    }
                                }} onClick={handleSubmit}>SAVE</LoadingButton>
                            </Navbar>
                            <Autocomplete
                                id="routine-name"
                                freeSolo
                                blurOnSelect
                                selectOnFocus
                                value={routineState.title}
                                options={workoutRoutineStore.dailyRoutines}
                                getOptionLabel={(option: DailyRoutine | string) => {
                                    if (typeof option === "string") {
                                        return option;
                                    }
                                    return option.title;
                                }}
                                onInputChange={(event, value) => handleSetTitle(event, value)}
                                onChange={(event, value) => handleSelectRoutine(event, value)}
                                renderInput={(params) =>
                                    <StyledTitleTextField {...params}
                                        label="Routine Name..."
                                        variant="outlined"
                                        size={isMobile ? 'small' : 'medium'}
                                        required
                                        error={titleError}
                                    />}
                                renderOption={(props: any, option: DailyRoutine | string) => {
                                    if (typeof option === "string") {
                                        return <Box {...props} key={routineState.id || 'key'}>{option}</Box>;
                                    }
                                    if (routineState.dayOfWeek !== option.dayOfWeek) {
                                        if (option.dayOfWeek === null) {
                                            return <Box {...props} key={option.id}>{"OPEN | " + option.title}</Box>;
                                        }
                                        return <Box {...props} key={option.id}>{option.dayOfWeek + " | " + option.title}</Box>;
                                    }
                                }}

                            />
                            <StyledDescriptionTextField
                                onChange={(e) => handleSetDescription(e.target.value)}
                                label="Add a Description to your Routine..."
                                variant="filled"
                                multiline
                                value={routineState.description}
                                rows={4}
                            />
                            <DividerStyle variant='middle' sx={{ bgcolor: '#38444D' }} />
                            <Stack spacing={3}>
                                {
                                    routineState.exercises.map((exercise: any, index: number) =>
                                        exercise.exerciseId >= 0 ? (
                                            <ExerciseContainer key={index} style={{ marginLeft: isMobile ? '2%' : '10%', marginBottom: '15px' }}>
                                                {
                                                    isMobile ?
                                                        <ExerciseMenuButtonComponent
                                                            key={index}
                                                            exercise={exercise}
                                                            index={index}
                                                            handleRemoveExercise={handleRemoveExercise}
                                                        />
                                                        :
                                                        <Button
                                                            variant="outlined"
                                                            size='medium'
                                                            color='error'
                                                            onClick={() => (handleRemoveExercise(index))}
                                                            style={{ position: 'absolute', right: '10px', top: '10px', minWidth: isMobile ? 'unset' : '', padding: isMobile ? '10px 15px 15px  ' : '' }}>{isMobile ? 'X' : 'Remove'}</Button>
                                                }
                                                <ExerciseTextField
                                                    label="Exercise Name"
                                                    autoComplete='off'
                                                    value={exercise.title}
                                                    size={isMobile ? 'small' : "medium"}
                                                    onChange={(e) => handleExerciseNameChange(e, exercise)}
                                                    style={{ width: isMobile ? '80%' : '35%' }}
                                                />
                                                <Divider sx={{ marginTop: isMobile ? '' : '20px', bgcolor: '#38444D' }} />

                                                <SetParentContainer>
                                                    {
                                                        exercise.repsWeight.map((rw: any, index2: number) =>
                                                            <SetContainer key={index2}>
                                                                <SetRemoveButton variant='outlined' color='error' size='medium' sx={{ minWidth: isMobile ? 'unset' : 'default', width: '10px' }} onClick={() => (handleRemoveSet(exercise, index2))}>X</SetRemoveButton>
                                                                <Divider flexItem orientation='vertical' sx={{ marginLeft: isMobile ? '5px' : '20px', bgcolor: 'white' }} />
                                                                <SetTypography>{index2 + 1}</SetTypography>
                                                                <RepsTextField
                                                                    size='small'
                                                                    autoComplete='off'
                                                                    onKeyPress={(event) => {
                                                                        if (!/[0-9]/.test(event.key)) {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                                    value={rw.reps === '' ? '' : rw.reps}
                                                                    onChange={(e) => handleRepsTextInput(e, exercise, index2)}
                                                                    inputProps={{ max: 999, inputMode: 'numeric' }}
                                                                    InputProps={{
                                                                        endAdornment: < InputAdornment position="end" style={{ color: 'white' }} >
                                                                            <Typography>reps</Typography>
                                                                        </InputAdornment>
                                                                    }}
                                                                />
                                                                <WeightTextField
                                                                    size='small'
                                                                    autoComplete='off'
                                                                    onKeyPress={(event) => {
                                                                        if (!/[0-9.]/.test(event.key)) {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                                    value={(rw.weight) === '' ? '' : rw.weight}
                                                                    onChange={(e) => (handleWeightTextInput(e, exercise, index2))}
                                                                    inputProps={{ inputMode: 'numeric' }}
                                                                    InputProps={{
                                                                        endAdornment: < InputAdornment position="end" style={{ color: 'white' }} >
                                                                            <Typography>lbs/kg</Typography>
                                                                        </InputAdornment>
                                                                    }}
                                                                />
                                                            </SetContainer>
                                                        )
                                                    }
                                                </SetParentContainer>
                                                <SetButtonDiv>
                                                    <Divider sx={{ bgcolor: '#38444D' }} />
                                                    <AddSetButton variant="contained" size="small" onClick={() => handleAddSet(exercise)}>Add Set</AddSetButton>
                                                </SetButtonDiv>
                                            </ExerciseContainer>
                                        ) : null
                                    )
                                }
                                <div>
                                    <ButtonContainer>
                                        <Button variant="contained" type="submit" size="large" onClick={handleAddExercise} sx={{ bgcolor: '#1D9BF0', marginBottom: '10px' }}>Add Exercise</Button>
                                    </ButtonContainer>
                                </div>
                            </Stack>
                        </>}
                </Wrapper>
            </Container>

        </>
    )
}

const Container = styled.div`
  /* width: 100%;
  background: #192633;
  position: fixed;
  display: flex;
  justify-content: center;
  overflow: auto;
  height: 100%; */
  ${baseContainerStyles}
`
const Wrapper = styled.div`
  width: ${isMobile ? '100%' : '85%'};
  background-color: #15202b;
  position: relative;
  overflow: auto;
  padding-bottom: ${Capacitor.isNativePlatform() ? 'calc(80px + env(safe-area-inset-bottom))' : '60px'};

`
const Navbar = styled.div`
  height: 60px;
  width: 100%;
  position: relative;
  display: flex;
`
const DividerStyle = styled(Divider)`
	margin-top: 30px;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`

const ExerciseContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  position: relative;
  background: #15202B;
  width: ${isMobile ? '96%' : '80%'};
  margin-top: 30px;
  min-height: 20vh;
  border: 1px solid #7C8A97;
  
`

const SetButtonDiv = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;

`
const AddSetButton = styled(Button)`
  width: 100%;
  justify-content: flex-start;
  border-radius: 1px;
  
  color: white;
  background-color: #15202B;
  padding-left: 11px;
  :hover {
    background-color: #2C3640;
  }
  :focus{
    outline: none;
  }
`
const ExerciseTextField = styled(TextField)({
    marginTop: '10px',
    marginLeft: '10px',
    height: '56px',
    '& .MuiInputLabel-root': {
        color: '#7C8A97',
    },
    '& label.Mui-focused': {
        color: 'lightgrey',
    },
    '& .MuiOutlinedInput-input': {
        color: 'white',
    },
    '& .MuiOutlinedInput-root': {
        backgroundColor: '#273340',
        '& fieldset': {
            borderColor: 'grey',
        },
        '&:hover fieldset': {
            borderColor: 'lightgrey',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'lightgrey',
        },
    },
});

const SetParentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
`

const SetContainer = styled.div`
  margin-top: 10px;
  margin-left: 11px;
  display:flex;
  flex-direction: row;
`

const SetRemoveButton = styled(Button)({

})

const SetTypography = styled.div`
  margin-left: ${isMobile ? '9px' : '20px'};
  color: white;
  font-size: 22px;
  min-width: 24px;
`

const StyledTitleTextField = styled(TextField)({
    marginTop: '2%',
    width: '80%',
    marginLeft: '10%',
    font: 'bold',
    '& label.Mui-focused': {
        color: 'lightgrey',
    },
    '& .MuiOutlinedInput-input': {
        color: 'white',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#808080',
        },
        '&:hover fieldset': {
            borderColor: 'lightgrey',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'lightgrey',
        },
        '& .MuiIconButton-root': {
            color: 'white',
        },
    },
    '& .MuiInputLabel-root': {
        color: '#7C8A97',
    },
})

const StyledDescriptionTextField = styled(TextField)({
    marginLeft: '10%',
    width: '80%',
    '& label.Mui-focused': {
        color: 'lightgrey',
    },
    '& .MuiFilledInput-input': {
        color: 'white',
    },
    '& .MuiFilledInput-root': {
        '&:before': {
            borderBottomColor: '#808080',
        },
        '&:hover:before': {
            borderBottomColor: '#bdbdbd',
        },
        '&.Mui-focused:after': {
            borderBottomColor: 'lightgrey',
        },
    },
    '& .MuiInputLabel-root': {
        color: '#7C8A97',
    },
})

const RepsTextField = styled(TextField)({
    marginLeft: '20px',
    width: '35%',
    '& .MuiInputLabel-root': {
        color: '#7C8A97',
    },
    '& label.Mui-focused': {
        color: 'lightgrey',
    },
    '& .MuiOutlinedInput-input': {
        color: 'white',
    },
    '& .MuiOutlinedInput-root': {
        backgroundColor: '#273340',
        '& fieldset': {
            borderColor: 'grey',
        },
        '&:hover fieldset': {
            borderColor: 'lightgrey',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'lightgrey',
        },
    },
});

const WeightTextField = styled(TextField)({
    paddingRight: '5px',
    marginLeft: '20px',
    width: '35%',
    '& .MuiInputLabel-root': {
        color: '#7C8A97',
    },
    '& label.Mui-focused': {
        color: 'lightgrey',
    },
    '& .MuiOutlinedInput-input': {
        color: 'white',
    },
    '& .MuiOutlinedInput-root': {
        backgroundColor: '#273340',
        '& fieldset': {
            borderColor: 'grey',
        },
        '&:hover fieldset': {
            borderColor: 'lightgrey',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'lightgrey',
        },
    },
});

const StyledMenu = styled(Menu)({
    '& .MuiPaper-root': {
        backgroundColor: '#192633',
        color: 'white'
    }
})

export default LoggedWorkout