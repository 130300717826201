import React, { useEffect, useState } from 'react'
import axios from 'axios';
import config from '../../config';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { Button, TextField, Typography, InputBase, InputAdornment, IconButton, Alert, CircularProgress } from '@mui/material'
import { AppleLoginButton, GoogleLoginButton } from 'react-social-login-buttons';
import { auth, signInWithApple, signInWithGoogle, signupWithEmail } from '../../libs/firebase';
import { UserCredential, User } from '@firebase/auth';
import Cookies from 'js-cookie';
import { Divider } from '@mui/material';
import { isMobile } from 'react-device-detect';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LoadingButton from '@mui/lab/LoadingButton';
import { Capacitor } from '@capacitor/core';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { setToken, setPhotoUrl, setUserId, getPhotoUrl } from '../../utils/mobileStorage';

const LandingPageContainer = styled.div`
    /* background-color: #192633; */
    display: flex;
    flex-direction: ${isMobile ? 'column' : 'row'};
    width: 100vw;
    height: 100vh;
`

const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1.5;
  background-color: #141d27;
`

const LeftSection = styled.div`
  display: ${isMobile ? 'none' : 'flex'};
  justify-content: center;
  align-items: center;
  flex: 0.5;
  background-color: #192633; 
`


const StyledLogoContainer = styled.div`
    display: flex; 
    flex-direction: row; 
    align-items: center; 
    justify-content: center; 
    height: 200px; 
    margin-top: ${isMobile && window.innerHeight > 700 ? '15%' : isMobile ? '1%' : '7%'};   
`

const StyledMobileLogoContainer = styled.div`
    display: ${isMobile ? 'flex' : 'none'}; 
    position: absolute;
    top: 0;
    height: 200px; 
    margin-top: ${isMobile && window.innerHeight > 700 ? (Capacitor.getPlatform() === 'ios' ? 'env(safe-area-inset-top)' : '5%') : '1%'};
`


const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const LoadingContainer = styled.div`
    height: 100vh;
    width: 100vw;
    background-color: #192633;
`
const StyledDivContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 15px;
`

const StyledDivider = styled(Divider)`
    color: white;
    background-color: white;
    flex: 1.45;
    margin-top: 20px;
`

const StyledCreateAccountButton = styled(Button)`
    width: ${isMobile ? '300px' : '400px'};
    margin-left: 5px;
    height: 50px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: none;
    font-size: 19px;
    font-family: "Google Sans", arial, sans-serif;
`

const StyledSignupContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #141d27;
    width: ${isMobile ? '100%' : '50%'};
    padding-bottom:10px;
`

const StyledCreateAccountHeader = styled(Typography)`
    align-self: flex-start;
    margin-left: 15%;
    margin-bottom: 15px;
    margin-top: 20px;
`

const StyledSignupTextField = styled(TextField)({
    width: '70%',
    marginBottom: '20px',
    // border: '1px solid #808080',
    borderRadius: '4px',
    // '&:hover': {
    //     borderColor: 'lightgrey',
    // },
    // '& .Mui-focused': {
    //     border: '1px solid lightgrey',
    // },
    // '& label.Mui-focused': {
    //     color: 'lightgrey',
    // },
    // '& .MuiFilledInput-input': {
    //     color: 'white',
    // },
    // '& .MuiInputLabel-root': {
    //     color: '#7C8A97',
    //     border: 'none',

    // },
})

const StyledSubmitButton = styled(LoadingButton)`
    height: 50px;
    width: 70%;
    border-radius: 20px;
    background-color: 'white';
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 17px;
    text-transform: none;

`


const SetRemoveButton = styled(Button)({
    position: 'absolute',
    top: 0,
    left: 5,
    minWidth: 'unset',
    color: 'white',
    borderColor: 'white',
})

const StyledErrorAlert = styled(Alert)`
    width: 70%;
    margin-top: 10px;
`



const Signup = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [isDataLoading, setIsDataLoading] = useState(false)
    const [isLoginError, setisLoginError] = useState(false);
    const [isSignUpClick, setIsSignUpClick] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [isSubmitLoading, setIsSubmitLoading] = useState(false)
    const [loginError, setLoginError] = useState('');
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const navigate = useNavigate()

    useEffect(() => {
        setIsLoading(true)
        if (Cookies.get('userId') && Cookies.get('jwt')) navigate('/')
        setIsLoading(false)

    }, [])

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleCreateAccountClick = async () => {
        setIsSignUpClick(true)
    }

    const handleSignUpXClick = () => {
        setLoginError('')
        setisLoginError(false)

        setIsSignUpClick(false)
        setName('')
        setEmail('')
        setPassword('')
    }

    const handleSignUpClick = async (type: string) => {
        try {
            if (Capacitor.isNativePlatform()) {
                let result
                if (type === 'apple') result = await FirebaseAuthentication.signInWithApple()
                else if (type === 'google') result = await FirebaseAuthentication.signInWithGoogle()
                setIsDataLoading(true)
                const currentUser = result?.user
                if (!currentUser) return
                const email = result?.user?.email
                const uuid = currentUser.uid
                const token2 = await FirebaseAuthentication.getIdToken()
                const token = token2.token

                const res = await axios.post(config.backend.url + 'login', { name: currentUser.displayName || 'unassigned', email: email?.toLowerCase(), userId: uuid }, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })


                //set user and token inside a cookie that expires in 30 days
                await setUserId(uuid)
                await setToken(token)

                if (currentUser.photoUrl) {
                    await setPhotoUrl(currentUser.photoUrl)
                    await getPhotoUrl()
                }
                navigate('/')
            } else {
                let result
                if (type === 'apple') result = await signInWithApple()
                else if (type === 'google') result = await signInWithGoogle()
                setIsDataLoading(true)
                const currentUser = result?.user as User
                const email = result?.user?.email
                const uuid = currentUser.uid
                const token = await currentUser.getIdToken()
                if (currentUser) {
                    const res = await axios.post(config.backend.url + 'login', { name: currentUser.displayName || 'unassigned', email: email?.toLowerCase(), userId: uuid }, {
                        headers: {
                            "Authorization": `Bearer ${token}`
                        }
                    })
                    //set user and token inside a cookie that expires in 30 days
                    Cookies.set('jwt', token, { expires: (1 / 24), secure: config.cookieSecure });
                    Cookies.set('userId', uuid, { expires: (1 / 24), secure: config.cookieSecure });
                    if (currentUser.photoURL) {
                        Cookies.set('photoUrl', currentUser.photoURL, { expires: 30, secure: config.cookieSecure })
                    }
                    navigate('/')
                }
            }
        } catch (err) {
            //clear cookies and signout
            Cookies.remove('jwt')
            Cookies.remove('userId')
            Cookies.remove('photoUrl')
            await auth.signOut()
            // console.log(err)
        }
        setIsDataLoading(false)
    }

    const handleSubmitClicked = async () => {
        try {
            if (name === '') {
                setLoginError('Name cannot be empty')
                setisLoginError(true)
                return
            }
            if (Capacitor.isNativePlatform()) {
                setIsSubmitLoading(true)
                const result = await FirebaseAuthentication.createUserWithEmailAndPassword({ email, password })
                setIsSubmitLoading(false)
                setIsDataLoading(true)
                const currentUser = result.user
                const uuid = currentUser?.uid
                const myToken = await FirebaseAuthentication.getIdToken()
                const token = myToken.token
                if (currentUser && uuid) {
                    const res = await axios.post(config.backend.url + 'login', { name: name, email: email?.toLowerCase(), userId: uuid }, {
                        headers: {
                            "Authorization": `Bearer ${token}`
                        }
                    })
                    await setUserId(uuid)
                    await setToken(token)
                    navigate('/')
                }
            }
            else {
                setIsSubmitLoading(true)
                const result: UserCredential = await signupWithEmail(email, password)
                setIsSubmitLoading(false)
                setIsDataLoading(true)
                const currentUser = result.user as User
                const uuid = currentUser.uid
                const token = await currentUser.getIdToken()
                if (currentUser) {
                    const res = await axios.post(config.backend.url + 'login', { name: name, email: email?.toLowerCase(), userId: uuid }, {
                        headers: {
                            "Authorization": `Bearer ${token}`
                        }
                    })
                    //set user and token inside a cookie that expires in 30 days
                    Cookies.set('jwt', token, { expires: (1 / 24), secure: config.cookieSecure });
                    Cookies.set('userId', uuid, { expires: (1 / 24), secure: config.cookieSecure });

                    navigate('/')
                }
            }
        } catch (err: any) {
            const errorMessage = err.message;
            const error = errorMessage.substring(errorMessage.indexOf('(') + 1, errorMessage.indexOf(')'))
            if (error === 'auth/weak-password' || err.code === 'weak-password') {
                setLoginError('Password should be at least 6 characters')
                setisLoginError(true)
            }
            if (error === 'auth/email-already-in-use' || err.code === 'email-already-in-use') {
                setLoginError('Email already in use')
                setisLoginError(true)
            }
            if (error === 'auth/invalid-email' || err.code === 'invalid-email') {
                setLoginError('This Email is not Valid')
                setisLoginError(true)
            }
            if (error === 'auth/missing-password' || err.code === 'wrong-password') {
                setLoginError('Password should be at least 6 characters')
                setisLoginError(true)
            }
            //clear cookies and signout
            Cookies.remove('jwt')
            Cookies.remove('userId')
            Cookies.remove('photoUrl')
            await auth.signOut()
        }
        setIsSubmitLoading(false)
        setIsDataLoading(false)
    }

    const handleSetName = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setLoginError('')
        setisLoginError(false)
        setName(e.target.value)

    }

    return (
        <>
            {
                (isLoading || isDataLoading)
                    ?
                    <LoadingContainer>
                        {
                            Capacitor.isNativePlatform() ?
                                <div style={{ position: 'absolute', top: '10%', width: '100%', textAlign: 'center' }}>
                                    <CircularProgress />
                                </div>
                                :
                                <div style={{ color: "white" }}>Redirecting you</div>
                        }
                    </LoadingContainer>
                    :
                    <>
                        <LandingPageContainer>
                            <LeftSection>
                                <StyledLogoContainer>
                                    <Typography variant='h2' color='white' fontSize={'120px'}>Swole</Typography>
                                </StyledLogoContainer>
                            </LeftSection>
                            <RightSection>
                                <StyledMobileLogoContainer>
                                    <Typography variant='h2' color='white' fontSize={'120px'}>Swole</Typography>
                                </StyledMobileLogoContainer>
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px', marginBottom: '30px' }}>
                                    <Typography variant='h5' color='white' fontSize={(isMobile && window.innerWidth < 380) ? '18px' : isMobile ? '20px ' : '30px'}>The only Fitness tracker you will ever need</Typography>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                                    <Typography variant='h5' color='white' fontFamily={'Roboto'} fontSize={(isMobile && window.innerWidth < 380) ? '18px' : isMobile ? '20px ' : '30px'}>Sign up</Typography>
                                </div>
                                {
                                    isSignUpClick
                                        ?
                                        <StyledSignupContainer>
                                            <SetRemoveButton variant='outlined' size='small' sx={{ width: '30px' }} onClick={() => handleSignUpXClick()} >X</SetRemoveButton>
                                            <StyledCreateAccountHeader variant='h5' color='white' >Create your account</StyledCreateAccountHeader>
                                            <StyledSignupTextField variant='filled' label='Name ' onChange={(e) => (handleSetName(e))}
                                                InputProps={{
                                                    disableUnderline: true
                                                }}
                                                sx={{
                                                    border: isLoginError ? '1px solid red' : '1px solid #808080',
                                                    '&:hover': { borderColor: isLoginError ? 'red' : 'lightgrey' },
                                                    '& .Mui-focused': { border: isLoginError ? '1px solid red' : '1px solid lightgrey' },
                                                    '& label.Mui-focused': { color: isLoginError ? 'red' : 'lightgrey' },
                                                    '& .MuiFilledInput-input': { color: 'white' },
                                                    '& .MuiInputLabel-root': { color: (isLoginError ? 'red' : '#7C8A97'), border: 'none' },
                                                }}

                                            />
                                            <StyledSignupTextField variant='filled' label='Email ' onChange={(e) => (setEmail(e.target.value), setisLoginError(false), setLoginError(''))}
                                                InputProps={{
                                                    disableUnderline: true
                                                }}
                                                sx={{
                                                    border: isLoginError ? '1px solid red' : '1px solid #808080',
                                                    '&:hover': { borderColor: isLoginError ? 'red' : 'lightgrey' },
                                                    '& .Mui-focused': { border: isLoginError ? '1px solid red' : '1px solid lightgrey' },
                                                    '& label.Mui-focused': { color: isLoginError ? 'red' : 'lightgrey' },
                                                    '& .MuiFilledInput-input': { color: 'white' },
                                                    '& .MuiInputLabel-root': { color: (isLoginError ? 'red' : '#7C8A97'), border: 'none' },
                                                }}

                                            />
                                            <StyledSignupTextField variant='filled' label='Password '
                                                type={showPassword ? 'text' : 'password'}
                                                onChange={(e) => (setPassword(e.target.value), setisLoginError(false), setLoginError(''))}
                                                InputProps={{
                                                    disableUnderline: true,
                                                    endAdornment: (
                                                        < InputAdornment position="end" >
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                                edge="end"
                                                            >
                                                                {showPassword ? <VisibilityOff sx={{ color: 'white' }} /> : <Visibility sx={{ color: 'white' }} />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                                sx={{
                                                    border: isLoginError ? '1px solid red' : '1px solid #808080',
                                                    '&:hover': { borderColor: isLoginError ? 'red' : 'lightgrey' },
                                                    '& .Mui-focused': { border: isLoginError ? '1px solid red' : '1px solid lightgrey' },
                                                    '& label.Mui-focused': { color: isLoginError ? 'red' : 'lightgrey' },
                                                    '& .MuiFilledInput-input': { color: 'white' },
                                                    '& .MuiInputLabel-root': { color: (isLoginError ? 'red' : '#7C8A97'), border: 'none' },
                                                }}
                                            />
                                            <StyledSubmitButton variant='contained' loading={isSubmitLoading} loadingPosition="start" sx={{
                                                ':disabled': {
                                                    backgroundColor: '#868686'
                                                }
                                            }} onClick={() => handleSubmitClicked()}>Submit</StyledSubmitButton>
                                            <Typography color='white'>
                                                Already have an Account?
                                                <a href="/login"> Log in</a>
                                            </Typography>
                                            <StyledErrorAlert severity="error" variant='filled' hidden={!isLoginError}>{loginError}</StyledErrorAlert>

                                        </StyledSignupContainer>
                                        :
                                        <>
                                            <ButtonContainer>
                                                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                                    <GoogleLoginButton text='Sign up with Google' style={{
                                                        width: isMobile ? '300px' : '400px',
                                                        borderRadius: '20px',
                                                        fontFamily: '"Google Sans", arial, sans-serif',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }} onClick={() => handleSignUpClick('google')} />
                                                    {
                                                        Capacitor.getPlatform() !== 'android' &&
                                                        <AppleLoginButton text='Sign up with Apple' style={{
                                                            width: isMobile ? '300px' : '400px',
                                                            borderRadius: '20px',
                                                            fontFamily: '"Google Sans", arial, sans-serif',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            marginTop: '10px'
                                                        }} onClick={() => handleSignUpClick('apple')} />
                                                    }
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <StyledDivider />
                                                        <Typography color='white' sx={{ margin: '10px', flex: '0.1' }}>or</Typography>
                                                        <StyledDivider />
                                                    </div>
                                                </div>
                                                <StyledCreateAccountButton variant='contained' onClick={() => handleCreateAccountClick()}>Create an account</StyledCreateAccountButton>

                                            </ButtonContainer>
                                            <StyledDivContainer>
                                                <Typography color='white'>
                                                    Already have an Account?
                                                    <a href="/login"> Log in</a>
                                                </Typography>
                                            </StyledDivContainer>
                                        </>
                                }
                            </RightSection>
                        </LandingPageContainer>
                    </>
            }
        </>
    )
}

export default Signup