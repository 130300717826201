import React, { useEffect, useState } from 'react'
import { Card, InputAdornment, TextField, ToggleButton, ToggleButtonGroup, Typography, Divider, Button, CircularProgress } from '@mui/material'
import styled from '@emotion/styled'
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import CalendarTodayTwoToneIcon from '@mui/icons-material/CalendarTodayTwoTone';
import LoggedRoutine from '../types/Logged/LoggedRoutine';
import LoggedExercise from '../types/Logged/LoggedExercise';
import { useNavigate } from 'react-router-dom';
import { useInfiniteQuery } from 'react-query'
import config from '../config';
import axios from 'axios';
import { useInView } from 'react-intersection-observer'
//@ts-ignore
import { useDebounce } from "@uidotdev/usehooks";
import { isMobile } from 'react-device-detect';
import ErrorConfig from '../utils/ErrorConfig';
import { checkCookies } from '../libs/api';
import { getToken, getUserId } from '../utils/mobileStorage';
import { baseContainerStyles } from './styled/baseStyles';
import { observer } from 'mobx-react-lite';
import mobileSelectedStore from '../store/mobileSelectedStore';

const Container = styled.div`
    ${baseContainerStyles}
  /* width: 100%;
  height: 100%;
  background: #192633;
  position: fixed;
  display: flex;
  justify-content: center;
  overflow: auto;  */
`
const Wrapper = styled.div`
  width: ${isMobile ? '100%' : '85%'};
  padding-bottom: 50px;
  background-color: #15202b;
  position: relative;
  overflow: auto;
  overflow-x: hidden;
  padding-bottom: 100px;
`

const SearchContainer = styled.div`
    width: ${isMobile ? '92%' : '80%'}; 
    margin-top: ${isMobile ? '5%' : '2%'};
    margin-left: ${isMobile ? '4%' : '10%'};
    display: flex;
    flex-direction: row;
`

const StyledTextFieldContainer = styled.div`
    flex-grow: 1;
`

const StyledTextField = styled(TextField)({
    font: 'bold',
    width: '100%',
    '& label.Mui-focused': {
        color: 'lightgrey',
    },
    '& .MuiOutlinedInput-input': {
        color: 'white',
        '&::placeholder': {
            fontSize: '15px',
        },
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#808080',
        },
        '&:hover fieldset': {
            borderColor: 'lightgrey',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'lightgrey',
        },
    },
    '& .MuiInputLabel-root': {
        color: '#7C8A97',
    },
    "& .MuiInputBase-root": {
        height: isMobile ? '45px' : ''
    }
})

const StyledButtonGroup = styled(ToggleButtonGroup)({
    marginLeft: '2%',
})

const StyledToggleButton = styled(ToggleButton)({
    color: 'grey',
    height: isMobile ? '45px' : '55px',
    fontSize: isMobile ? '13px' : '',
    fontWeight: isMobile ? 'bold' : '',
    backgroundColor: '#445569',
    '&.Mui-selected': {
        color: 'lightgrey',
        backgroundColor: '#E29E21',
        ":hover": {
            color: 'lightgrey',
            backgroundColor: '#E29E21'
        }
    },
    ":hover": {
        color: 'white',
        backgroundColor: '#5f5f75'
    },
    '&: focus': {
        outline: 'none'
    }
})

const ContentContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  position: relative;
  background: #15202B;
  width: ${isMobile ? '92%' : '80%'};
  margin-top: 30px;
  min-height: 15vh;
  border: 1px solid #7C8A97;
  cursor: pointer;
`

const ExerciseTextField = styled(TextField)({
    marginTop: '10px',
    marginLeft: '10px',
    width: isMobile ? '80%' : '35%',
    height: '56px',
    '& label.Mui-disabled': {
        color: 'lightgrey',
    },
    '& .MuiOutlinedInput-root': {
        backgroundColor: '#273340',
        '& fieldset': {
            borderColor: 'grey',
        },
    },
    "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "#bdbbbb",
    },
});


const SetParentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
`
const SetContainer = styled.div`
  margin-top: 10px;
  margin-left: 11px;
  display:flex;
  flex-direction: row;
`
const SetTypography = styled.div`
  margin-left: ${isMobile ? '9px' : '20px'};
  color: white;
  font-size: 22px;
  min-width: 24px;
`

const SetButtonDiv = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;

`
const AddSetButton = styled(Button)({
    width: '100%',
    justifyContent: 'flex-start',
    borderRadius: '1px',

    backgroundColor: '#15202B',
    paddingLeft: '11px',
    "&.Mui-disabled": {
        color: "#bdbbbb"
    }
})

const RepsTextField = styled(TextField)({
    marginLeft: '20px',
    width: '35%',
    '& .MuiOutlinedInput-root': {
        backgroundColor: '#273340',

    },
    "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "#bdbbbb",
    },
});

const WeightTextField = styled(TextField)({
    marginLeft: '20px',
    width: '35%',
    '& .MuiOutlinedInput-root': {
        backgroundColor: '#273340',

    },
    "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "#bdbbbb",
    },


})

const StyledTypography = styled(Typography)({
    marginBottom: '10px',
    color: 'white'
})

const StyledEmptyContainer = styled.div`
    background-color: #5c5c5c65;    
    color: white;
    text-align: center;
    margin-top: 10px;
`


const ProgressComponent = () => {
    const { ref, inView } = useInView()
    const [toggleState, setToggleState] = useState<string>(sessionStorage.getItem('toggleState') || 'Routine')
    const [queryState, setQueryState] = useState(sessionStorage.getItem('queryState') || '')

    const moment = require('moment')
    const navigate = useNavigate()
    const debounceValue = useDebounce(queryState, 300);

    useEffect(() => {
        if (inView && searchQuery.hasNextPage) {
            searchQuery.fetchNextPage()
        }
        sessionStorage.removeItem('toggleState')
        sessionStorage.removeItem('queryState')

        //sessionStorage.clear()
    }, [inView])

    const handleToggleChange = (e: React.MouseEvent<HTMLElement, MouseEvent>, newToggleValue: string) => {
        if (newToggleValue !== null) {
            setToggleState(newToggleValue)
            setQueryState('')
            sessionStorage.removeItem('queryState')
        }

    }

    const handleRoutineClick = (routineState: LoggedRoutine) => {
        sessionStorage.setItem('toggleState', toggleState)
        sessionStorage.setItem('queryState', queryState)
        const data = {
            routineState
        }
        mobileSelectedStore.setFromProgressPage(true)
        navigate(`/loggedroutine`, { state: data })
    }

    const handleExerciseClick = async (routineId: number | undefined) => {
        if (routineId === undefined) {
            return null
        }
        await checkCookies()
        sessionStorage.setItem('toggleState', toggleState)
        sessionStorage.setItem('queryState', queryState)
        const routineData = {
            //userId: '9d51c2fb-b664-4dfa-a009-4d0e5bf3c991',
            // userId: sessionStorage.getItem('uuid'),
            userId: await getUserId(),
            id: routineId.toString()
        }
        try {
            const res = await axios.post(config.backend.url + 'loggedWorkout/search/get', routineData, {
                headers: {
                    "Authorization": await getToken()
                }
            })
            const data = {
                routineState: res.data?.data.LoggedWorkoutModel[0]
            }
            mobileSelectedStore.setFromProgressPage(true)
            navigate(`/loggedroutine`, { state: data })
        } catch (err) {
            ErrorConfig(err)
        }
    }

    const getSearchResults = async (queryState: string, pageParam: string) => {
        if (!queryState) {
            return null
        }
        try {
            await checkCookies()
            if (toggleState === 'Routine') {
                const data = {
                    //userId: '9d51c2fb-b664-4dfa-a009-4d0e5bf3c991',
                    // userId: sessionStorage.getItem('uuid'),
                    userId: await getUserId(),
                    title: queryState,
                    cursor: pageParam.toString()
                }

                const res = await axios.post(config.backend.url + 'loggedWorkout/search/title', data, {
                    headers: {
                        "Authorization": await getToken()
                    }
                })

                const newData = {
                    loggedWorkouts: res.data?.data.LoggedWorkoutModel,
                    nextId: res.data?.data.nextId
                }
                //await new Promise((resolve) => setTimeout(resolve, Math.random() * 1000))
                return newData
            }

            if (toggleState === 'Exercise') {
                const data = {
                    //userId: '9d51c2fb-b664-4dfa-a009-4d0e5bf3c991',
                    // userId: sessionStorage.getItem('uuid'),
                    userId: await getUserId(),
                    title: queryState,
                    cursor: pageParam.toString()
                }
                const res = await axios.post(config.backend.url + 'loggedExercise/search/title', data, {
                    headers: {
                        "Authorization": await getToken()
                    }
                })

                const newData = {
                    loggedWorkouts: res.data?.data.LoggedExerciseModel,
                    nextId: res.data?.data.nextId
                }
                // await new Promise((resolve) => setTimeout(resolve, Math.random() * 1000))
                return newData
            }
        } catch (err) {
            ErrorConfig(err)
        }

    }
    const searchQuery = useInfiniteQuery({
        queryKey: ['searchResult2', debounceValue, toggleState],
        queryFn: ({ pageParam = '' }) => getSearchResults(debounceValue, pageParam),
        getNextPageParam: (lastPage) => lastPage?.nextId || false,
    })

    return (
        <>
            <Container>
                <Wrapper>
                    <SearchContainer>
                        <StyledTextFieldContainer>
                            <StyledTextField
                                placeholder={isMobile ? `Enter ${toggleState} name` : `Enter a ${toggleState} Name to Search For`}
                                autoComplete='off'
                                value={queryState}
                                onChange={(e) => (setQueryState(e.target.value))}
                            />
                        </StyledTextFieldContainer>
                        <StyledButtonGroup
                            color="primary"
                            value={toggleState}
                            exclusive
                            onChange={handleToggleChange}
                            aria-label="Platform"
                        >
                            <StyledToggleButton value='Routine' size={isMobile ? 'small' : 'medium'}>Routine</StyledToggleButton>
                            <StyledToggleButton value='Exercise' size={isMobile ? 'small' : 'medium'}>Exercise</StyledToggleButton>
                        </StyledButtonGroup>
                    </SearchContainer>
                    {
                        searchQuery.isLoading && queryState ?
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
                                <CircularProgress />
                            </div>
                            :
                            searchQuery.data?.pages[0]?.loggedWorkouts.length === 0 && queryState ?
                                <StyledEmptyContainer>
                                    <Typography style={{ fontSize: '30px' }} >No results found</Typography>
                                </StyledEmptyContainer>
                                :
                                (
                                    toggleState === 'Routine' && queryState ?
                                        (
                                            queryState && searchQuery.data?.pages.map((page) =>
                                                page &&
                                                <React.Fragment key={page?.nextId || 'lastPage'}>
                                                    {
                                                        page.loggedWorkouts?.map((routine: LoggedRoutine, index: number) =>
                                                            <ContentContainer key={index} style={{ marginLeft: isMobile ? '4%' : '10%', marginBottom: '15px', paddingLeft: '20px' }} onClick={(e) => handleRoutineClick(routine)}>
                                                                <StyledTypography variant='h4' fontWeight='bold' style={{ marginTop: '10px' }}>{routine.title}</StyledTypography>
                                                                <div style={{ display: 'flex' }}>
                                                                    <CalendarTodayTwoToneIcon fontSize='small' style={{ color: 'lightgrey', marginRight: '5px' }} />
                                                                    <Typography style={{ color: 'lightgrey', marginBottom: '30px' }}>
                                                                        {moment(routine.futureDate).format('dddd, MMMM Do YYYY')}
                                                                    </Typography>
                                                                </div>
                                                                <Divider sx={{ bgcolor: '#38444D', marginBottom: '10px', marginLeft: isMobile ? '-4%' : '-10%', marginTop: '-1%' }} />
                                                                <div style={{ display: 'flex' }}>
                                                                    <FitnessCenterIcon color='primary' fontSize='small' style={{ marginRight: '5px', marginTop: '1px' }} />
                                                                    <StyledTypography style={{}}>Exercises:  </StyledTypography>
                                                                </div>
                                                                <StyledTypography style={{ marginLeft: '25px' }}>
                                                                    {routine.exercises?.map((exercise: LoggedExercise, index: number) =>
                                                                        <React.Fragment key={index}>
                                                                            {exercise.title}
                                                                            {index !== routine.exercises.length - 1 && ', '}
                                                                        </React.Fragment>

                                                                    )}
                                                                </StyledTypography>
                                                            </ContentContainer>
                                                        )}
                                                </React.Fragment>

                                            )

                                        )
                                        :
                                        (
                                            queryState && searchQuery.data?.pages.map((page) =>
                                                <React.Fragment key={page?.nextId || 'lastPage'}>
                                                    {
                                                        page?.loggedWorkouts?.map((exercise: LoggedExercise, index: number) =>
                                                            <ContentContainer key={`${exercise.title}${exercise.id}`} style={{ marginLeft: isMobile ? '2%' : '10%', marginBottom: '15px', width: isMobile ? '96%' : '80%' }} onClick={(e) => handleExerciseClick(exercise.loggedworkoutId)} >
                                                                <ExerciseTextField
                                                                    label="Exercise Name"
                                                                    autoComplete='off'
                                                                    value={exercise.title}
                                                                    size={isMobile ? 'small' : "medium"}
                                                                    disabled

                                                                />
                                                                <Divider sx={{ marginTop: isMobile ? '' : '20px', bgcolor: '#38444D' }} />
                                                                <SetParentContainer>
                                                                    {
                                                                        exercise.repsWeight?.map((rw: any, index2: number) =>
                                                                            <SetContainer key={`reps${rw.id}`} >
                                                                                <Button variant='outlined' size='medium' disabled sx={{
                                                                                    width: '10px', color: 'white', "&.Mui-disabled": {
                                                                                        color: "#bdbbbb"
                                                                                    },
                                                                                    minWidth: isMobile ? 'unset' : 'default'
                                                                                }}>X</Button>
                                                                                <Divider flexItem orientation='vertical' sx={{ marginLeft: isMobile ? '' : '20px', bgcolor: 'white' }} />
                                                                                <SetTypography>{index2 + 1}</SetTypography>
                                                                                <RepsTextField
                                                                                    size='small'
                                                                                    value={rw.reps}
                                                                                    disabled
                                                                                    InputProps={{
                                                                                        endAdornment: < InputAdornment position="end" style={{ color: 'white' }} >
                                                                                            <Typography>reps</Typography>
                                                                                        </InputAdornment>
                                                                                    }}
                                                                                />
                                                                                <WeightTextField
                                                                                    size='small'
                                                                                    disabled
                                                                                    value={rw.weight}
                                                                                    InputProps={{
                                                                                        endAdornment: < InputAdornment position="end" style={{ color: 'white' }} >
                                                                                            <Typography>lbs/kg</Typography>
                                                                                        </InputAdornment>
                                                                                    }}
                                                                                />
                                                                            </SetContainer>
                                                                        )
                                                                    }
                                                                </SetParentContainer>
                                                                <SetButtonDiv>
                                                                    <AddSetButton variant="contained" size="small" disabled>Add Set</AddSetButton>
                                                                </SetButtonDiv>
                                                            </ContentContainer>
                                                        )
                                                    }
                                                </React.Fragment>
                                            )
                                        )

                                )
                    }
                    {searchQuery.isFetchingNextPage && (
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
                            <CircularProgress />
                        </div>
                    )}
                    <span ref={ref} style={{ visibility: 'hidden' }}>intersection observer marker</span>
                </Wrapper>
            </Container>
        </>
    )
}

export default observer(ProgressComponent)
