import React from 'react'
import Navbar from '../components/Navbar';
import LoggedWorkout from '../components/routines/view/LoggedWorkoutComponent';
import MobileBottomBar from '../components/Mobile/MobileBottomBar';
import { isMobile } from 'react-device-detect';

const LoggedWorkoutPage = (props: any) => {
    return (
        <>
            <Navbar title={'Routine'} />
            <LoggedWorkout />
            {/* {isMobile && <MobileBottomBar />} */}
        </>
    )
}

export default LoggedWorkoutPage