import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { useState, ChangeEvent } from 'react';
import {
  TextField,
  Stack,
  Button,
  Divider,
  Card,
  Typography,
  InputAdornment,
  Menu,
  MenuItem,
  MenuProps,
  Autocomplete,
  CircularProgress,
  Box,
  Modal,
  Fade,
  Backdrop,
  IconButton
} from '@mui/material';
import getDayOfWeek from '../../utils/GetDayOfWeek';
import Exercise from '../../types/Exercise';
import Routine from '../../types/Routine';
import axios from 'axios';
import config from '../../config';
import { error } from 'console';
import workoutRoutineStore from '../../store/workoutRoutineStore';
import { set, toJS } from 'mobx';
import getRoutine from '../../utils/GetRoutine';
import { observer } from 'mobx-react-lite';
import DailyRoutine from '../../types/DailyRoutine';
import { useNavigate } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
//import { DeleteForever, Save, Check, AddBox } from '@mui/icons-material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SaveIcon from '@mui/icons-material/Save';
import CheckIcon from '@mui/icons-material/Check';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { isMobile } from 'react-device-detect';
import Cookies from 'js-cookie';
import { checkCookies } from '../../libs/api';
import { getToken, getUserId } from '../../utils/mobileStorage';
import { baseContainerStyles } from '../styled/baseStyles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SettingsIcon from '@mui/icons-material/Settings';
import ExerciseMenuButtonComponent from '../styled/ExerciseMenuButtonComponent';
import { Capacitor } from '@capacitor/core';

interface Data {
  id: number | null | undefined,
  title: string,
  description: string,
  dayOfWeek?: string,
  exercises: Exercise[]
  routineId?: number | null | undefined,
  userId: string,
  newDayOfWeek: string | null
}


function DailyRoutineComponent() {
  const [isFirstRender, setIsFirstRender] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isDataloading, setIsDataLoading] = useState(true);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)
  const [titleError, setTitleError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isSaved, setIsSaved] = useState(true)
  const [isUpdate, setIsUpdate] = useState(false)
  const [isDeleteSuccess, setIsDeleteSuccess] = useState(false)
  const [isTemplateData, setIsTemplateData] = useState(false)
  const [originalId, setOriginalId] = useState<number>()
  const [routineState, setRoutineState] = useState<Routine>({
    title: '',
    description: '',
    currentId: 0,
    exercises: [],
  });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const navigate = useNavigate()

  const [openModal, setOpenModal] = React.useState(false);
  const [openConfirmModal, setOpenConfirmModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);


  //update our routineState with the dailyRoutineTemplate if it exists
  useEffect(() => {
    const fetch = async () => {
      //only run on first render to load in data
      if (isFirstRender) {
        setIsDataLoading(true)
        setIsFirstRender(false)
      }
      //dont run if isSubmitted is false after its click so we dont get old data flashing
      if (!isSubmitted && !isFirstRender) {
        return
      }
      let storedDailyRoutine: DailyRoutine | undefined;
      await workoutRoutineStore.fetchRoutines();
      const today = getDayOfWeek();
      const dailyRoutineTemplate = toJS(workoutRoutineStore.dailyRoutines)
      if (today !== undefined) {
        storedDailyRoutine = getRoutine(dailyRoutineTemplate, today);
        if (storedDailyRoutine) {
          setIsUpdate(true);
          //set the originalId to the id of the storedDailyRoutine so we can compare later to see if they changed templates
          setOriginalId(storedDailyRoutine?.id)
          setRoutineState((prevState) => ({
            ...prevState,
            id: storedDailyRoutine?.id,
            dayOfWeek: storedDailyRoutine?.dayOfWeek,
            title: storedDailyRoutine?.title || '',
            description: storedDailyRoutine?.description || '',
            currentId: storedDailyRoutine?.exercises.length || prevState.currentId,
            exercises: storedDailyRoutine?.exercises
              ? storedDailyRoutine.exercises.map((exercise, index) => ({
                title: exercise.title,
                exerciseId: index,
                repsWeight: exercise.repsWeight.map((repsWeight) => ({
                  reps: repsWeight.reps,
                  weight: repsWeight.weight,
                })),
              }))
              : prevState.exercises,
          }));
        }
      }
      setIsDataLoading(false)
    }
    //call fetch
    fetch()

  }, [isSubmitted]);


  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleSubmit = async (e: any) => {
    setIsSuccess(false)
    setIsSubmitted(false)
    //title isnt blank
    if (routineState.title) {
      if (isSaved) {
        setIsSuccess(true)
        return
      }
      setIsLoading(true);
      //get dayOfWeek
      const getDay = getDayOfWeek();
      if (getDay) {
        try {
          await checkCookies()
          const data: Data = {
            //userId: '9d51c2fb-b664-4dfa-a009-4d0e5bf3c991',
            userId: await getUserId() || '',
            id: routineState.id,
            title: routineState.title,
            description: routineState.description,
            dayOfWeek: getDay,
            newDayOfWeek: getDay,
            exercises: routineState.exercises,
          }
          //check if we have a routine already, if we dont create one
          if (workoutRoutineStore?.workoutRoutine[0] === undefined) {
            const res = await axios.post(config.backend.url + 'workoutRoutine', {
              userId: await getUserId(),
              title: "Default Routine Name",
              description: "Default Routine Description",
            }, {
              headers: {
                "Authorization": await getToken()
              }
            })

            data.routineId = res.data.data.id
          }
          else {
            data.routineId = workoutRoutineStore.workoutRoutine[0].id
          }


          //check if its an update
          if (isUpdate) {
            //check if we hot swapped a different template in 
            if (isTemplateData && originalId !== routineState.id) {

              const newData = {
                oldId: originalId,
                oldRoutineId: workoutRoutineStore.workoutRoutine[0].id,
                newDayOfWeek1: null,
                ...data
              }

              //check if we are hot swapping to a null template, if it is we can use that template
              if (routineState.dayOfWeek === null) {
                await axios.put(config.backend.url + 'routine/null/update', newData, {
                  headers: {
                    "Authorization": await getToken()
                  }
                })
              }
              //else we create a new routine from the hot swapped template
              else {
                delete newData.id
                await axios.post(config.backend.url + 'routine/null/create', newData, {
                  headers: {
                    "Authorization": await getToken()
                  }
                })
              }

            }
            //if we didnt hot swap a different template in just update the routine
            else {
              await axios.put(config.backend.url + 'routine', data, {
                headers: {
                  "Authorization": await getToken()
                }
              })
            }
          }
          else if (routineState.dayOfWeek === null) {
            await axios.put(config.backend.url + 'routine/null/new', data, {
              headers: {
                "Authorization": await getToken()
              }
            })
          }
          //if its not an update just create the routine
          else {
            await axios.post(config.backend.url + 'routine', data, {
              headers: {
                "Authorization": await getToken()
              }
            })
          }
          //await new Promise((resolve) => setTimeout(resolve, 500))
          setIsTemplateData(false)
          setIsSubmitted(true)
          setIsSuccess(true)
          setIsLoading(false)
        }
        catch (e: any) {
          const errorMessage = e?.response?.data?.error?.message
          console.log('err')
        }
      }
    }
    else {
      setTitleError(true);
    }
  }

  // add exercise count for each time add exercise button is clicked
  const handleAddExercise = () => {
    setIsSuccess(false)
    setIsSaved(false)
    setIsDeleteSuccess(false)
    setRoutineState((prevState) => ({
      ...prevState,
      currentId: prevState.currentId + 1,
      exercises: [
        ...prevState.exercises,
        {
          exerciseId: prevState.currentId,
          title: '',
          repsWeight: [{
            reps: '',
            weight: '',
          }]
        }
      ]
    }))
  }
  //add new set to exercise
  const handleAddSet = (newExercise: Exercise) => {
    setIsSuccess(false)
    setIsSaved(false)
    setIsDeleteSuccess(false)
    setRoutineState((prevState) => ({
      ...prevState,
      exercises: prevState.exercises.map((ex: Exercise) => {
        if (newExercise.exerciseId === ex.exerciseId) {
          return {
            ...ex,
            repsWeight: [
              ...ex.repsWeight,
              {
                reps: '',
                weight: '',
              }
            ]
          }
        }
        return ex;
      })
    }));

  }
  const handleSetTitle = (event: React.SyntheticEvent<Element, Event>, e: string) => {
    if (event === null) {
      return
    }
    setTitleError(false)
    setIsSaved(false)
    setIsSuccess(false)
    setIsDeleteSuccess(false)
    setRoutineState((prevState) => ({ ...prevState, title: e }));
  }

  const handleSetDescription = (e: string) => {
    setTitleError(false);
    setIsSaved(false)
    setIsSuccess(false)
    setIsDeleteSuccess(false)
    setRoutineState((prevState) => ({ ...prevState, description: e }));
  }

  const handleRepsTextInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, exercise: Exercise, index: number) => {
    setIsSuccess(false)
    setIsSaved(false)
    setIsDeleteSuccess(false)
    let firstDigit = (e.target.value).toString().charAt(0);
    if (Number(e.target.value) > 999) {
      return;
    }
    else if (firstDigit === '0') {
      e.target.value = '0';
    }
    else if (e.target.value.length === 0) {
      e.target.value = '';
    }

    setRoutineState((prevState) => ({
      ...prevState,
      exercises: prevState.exercises.map((ex: Exercise) => {
        if (exercise.exerciseId !== ex.exerciseId) {
          return ex;
        }
        return {
          ...ex,
          repsWeight: ex.repsWeight.map((rw: any, i: number) => {
            if (i === index) {
              return {
                ...rw,
                reps: e.target.value,
              };
            }
            return rw;
          }),
        };
      }),
    }));
  }

  const handleWeightTextInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, exercise: Exercise, index: number) => {
    setIsSuccess(false)
    setIsSaved(false)
    setIsDeleteSuccess(false)
    let firstDigit = (e.target.value).toString().charAt(0);
    if (Number(e.target.value) > 999.9) {
      return;
    }
    else if (firstDigit === '0') {
      e.target.value = '0';
    }
    else if ((e.target.value.includes('.'))) {
      let parts = (e.target.value).split('.');
      if (parts.length > 1) {
        e.target.value = parts[0] + '.' + parts[1].substring(0, 1);
      }
    }
    setRoutineState((prevState) => ({
      ...prevState,
      exercises: prevState.exercises.map((ex: Exercise) => {
        if (exercise.exerciseId !== ex.exerciseId) {
          return ex;
        }
        return {
          ...ex,
          repsWeight: ex.repsWeight.map((rw: any, i: number) => {
            if (i === index) {
              return {
                ...rw,
                weight: e.target.value,
              }
            }
            return rw;
          }),
        };
      }),
    }));
  }

  const handleExerciseNameChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, newExercise: Exercise) => {
    setIsSuccess(false)
    setIsSaved(false)
    setIsDeleteSuccess(false)
    setRoutineState((prevState) => ({
      ...prevState,
      exercises: prevState.exercises.map((exer: Exercise) => {
        if (newExercise.exerciseId === exer.exerciseId) {
          return {
            ...exer,
            title: e.target.value,
          }
        }
        return exer
      }),
    }))
  }

  const handleRemoveSet = (exercise: Exercise, index: number) => {
    setIsSuccess(false)
    setIsSaved(false)
    setIsDeleteSuccess(false)
    setRoutineState((prevState) => ({
      ...prevState,
      exercises: prevState.exercises.map((ex: Exercise) => {
        if (exercise.exerciseId === ex.exerciseId) {
          return {
            ...ex,
            repsWeight: ex.repsWeight.filter((rw: any, i: number) => i !== index),
          }
        }
        return ex;
      })
    }))
  }

  const handleRemoveExercise = (index: number) => {
    setIsSuccess(false)
    setIsSaved(false)
    setIsDeleteSuccess(false)
    setRoutineState((prevState) => ({
      ...prevState,
      exercises: prevState.exercises.filter((ex: Exercise, i: number) => i !== index)
    }))

    handleClose();
  }

  const handleExitButton = () => {
    navigate('/routine')
  }

  const handleSelectRoutine = (event: React.SyntheticEvent<Element, Event>, selectedRoutine: DailyRoutine | string | null) => {
    setIsSuccess(false)
    setIsSaved(false)
    setIsDeleteSuccess(false)
    if (selectedRoutine === null || typeof selectedRoutine === 'string') {
      return;
    }
    setIsTemplateData(true)
    setRoutineState((prevState) => ({
      ...prevState,
      id: selectedRoutine?.id,
      title: selectedRoutine?.title || '',
      dayOfWeek: selectedRoutine?.dayOfWeek,
      description: selectedRoutine?.description || '',
      currentId: selectedRoutine?.exercises.length || prevState.currentId,
      exercises: selectedRoutine?.exercises
        ? selectedRoutine.exercises.map((exercise: any, index: number) => ({
          title: exercise.title,
          exerciseId: index,
          repsWeight: exercise.repsWeight.map((repsWeight: any) => ({
            reps: repsWeight.reps,
            weight: repsWeight.weight,
          })),
        }))
        : prevState.exercises,
    }));
  };

  const handleDeleteRoutineButton = async () => {
    setIsDeleteLoading(true)
    setIsUpdate(false)
    try {
      if (routineState.id) {
        await checkCookies()
        const data = {
          id: routineState.id,
          //userId: '9d51c2fb-b664-4dfa-a009-4d0e5bf3c991'
          userId: await getUserId()
        }
        const res = await axios.post(config.backend.url + 'routine/delete', data, {
          headers: {
            "Authorization": await getToken()
          }
        })
        workoutRoutineStore.fetchRoutines()
        //await new Promise((resolve) => setTimeout(resolve, 500))
        setIsDeleteLoading(false)
        setIsDeleteSuccess(true)
        setRoutineState({
          title: '',
          description: '',
          currentId: 0,
          exercises: [],
        });
      }
    }
    catch (err) {
      console.log(err)
      setIsDeleteLoading(false)
    }
    setIsDeleteLoading(false)
  }

  const handleCreateRoutineButton = async () => {
    //setIsUpdate(false)
    setIsTemplateData(true)
    setRoutineState({
      title: '',
      description: '',
      currentId: 0,
      exercises: [],
    });
  }

  const handleMoreIconClick = () => {
    setOpenModal(true)
  }
  return (
    <>
      <Container>
        <Wrapper>
          {isDataloading ?
            <div style={{ position: 'absolute', top: '10%', width: '100%', textAlign: 'center' }}>
              <CircularProgress />
            </div>
            :
            <>
              <Navbar>
                {isMobile
                  ? <SettingsIcon sx={{ position: 'absolute', left: '30px', marginTop: '18px', color: 'white' }} onClick={handleMoreIconClick} />
                  : <Button variant="contained" size="large" color="warning" onClick={handleExitButton} sx={{ position: 'absolute', left: '10px', marginTop: '10px' }}>EXIT</Button>}
                <LoadingButton variant="contained" type="submit" size="large" loading={isLoading} loadingPosition="start" startIcon={isSuccess ? <CheckIcon /> : <SaveIcon />} sx={{
                  position: 'absolute', right: '10px', marginTop: '10px', bgcolor: isSuccess ? 'green' : '#1D9BF0', ':disabled': {
                    bgcolor: '#ccc'
                  },
                  ':hover': {
                    bgcolor: isSuccess ? 'green' : '#1D9BF0',
                  }
                }} onClick={handleSubmit}>SAVE</LoadingButton>
              </Navbar>
              <Autocomplete
                id="routine-name"
                freeSolo
                blurOnSelect
                selectOnFocus
                value={routineState.title}
                options={workoutRoutineStore.dailyRoutines}
                getOptionLabel={(option: DailyRoutine | string) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  return option.title;
                }}
                onInputChange={(event, value) => handleSetTitle(event, value)}
                onChange={(event, value) => handleSelectRoutine(event, value)}
                renderInput={(params) =>
                  <StyledTitleTextField {...params}
                    label="Routine Name..."
                    variant="outlined"
                    size={isMobile ? 'small' : 'medium'}
                    required
                    error={titleError}
                  />}
                renderOption={(props: any, option: DailyRoutine | string) => {
                  if (typeof option === "string") {
                    return <Box {...props} key={routineState.id || 'key'}>{option}</Box>;
                  }
                  if (routineState.dayOfWeek !== option.dayOfWeek) {
                    if (option.dayOfWeek === null) {
                      return <Box {...props} key={option.id}>{"OPEN | " + option.title}</Box>;
                    }
                    return <Box {...props} key={option.id}>{option.dayOfWeek + " | " + option.title}</Box>;
                  }
                }}

              />
              <StyledDescriptionTextField
                onChange={(e) => handleSetDescription(e.target.value)}
                label="Add a Description to your Routine..."
                variant="filled"
                multiline
                value={routineState.description}
                rows={4}
              />
              {
                !isMobile &&
                <DeleteButtonContainer>
                  <LoadingButton variant="contained" type="button" size="medium" color="error" loading={isDeleteLoading} startIcon={isDeleteSuccess ? <CheckIcon /> : <DeleteForeverIcon />} loadingPosition='start' sx={{
                    position: 'absolute', marginTop: '10px', ':disabled': { bgcolor: '#ccc' }
                  }} onClick={handleDeleteRoutineButton} >{isMobile ? 'DELETE' : 'DELETE Routine'}</LoadingButton>
                  <Button variant="contained" size="medium" color="primary" sx={{ position: 'absolute', marginTop: '10px', right: '0px', marginRight: '10%' }} startIcon={<AddBoxIcon />} onClick={handleCreateRoutineButton}> {isMobile ? 'New' : 'CREATE New'}</Button>
                </DeleteButtonContainer>
              }
              {/* <ButtonContainer>
            <Button variant="contained" type="submit" size="large" onClick={handleAddExercise} sx={{ bgcolor: '#1D9BF0 ' }}>Add Exercise</Button>
          </ButtonContainer> */}
              <DividerStyle variant='middle' sx={{ bgcolor: '#38444D' }} />
              <Stack spacing={3}>
                {
                  routineState.exercises.map((exercise: any, index: number) =>
                    exercise.exerciseId >= 0 ? (
                      <ExerciseContainer key={index} style={{ marginLeft: isMobile ? '2%' : '10%', marginBottom: '15px', width: isMobile ? '96%' : '80%' }}>
                        {
                          isMobile
                            ?
                            <ExerciseMenuButtonComponent
                              key={index}
                              exercise={exercise}
                              index={index}
                              handleRemoveExercise={handleRemoveExercise}
                            />
                            :
                            <Button
                              variant="outlined"
                              size="medium"
                              color='error'
                              onClick={() => (handleRemoveExercise(index))}
                              style={{ position: 'absolute', right: '10px', top: '10px', minWidth: isMobile ? 'unset' : '', padding: isMobile ? '10px 15px 15px  ' : '' }}>{isMobile ? 'X' : 'Remove'}</Button>
                        }
                        <ExerciseTextField
                          label="Exercise Name"
                          autoComplete='off'
                          value={exercise.title}
                          size={isMobile ? 'small' : "medium"}
                          onChange={(e) => handleExerciseNameChange(e, exercise)}
                          style={{ width: isMobile ? '80%' : '35%' }}
                        />
                        <Divider sx={{ marginTop: isMobile ? '' : '20px', bgcolor: '#38444D' }} />

                        <SetParentContainer>
                          {
                            exercise.repsWeight.map((rw: any, index2: number) =>
                              <SetContainer key={index2}>
                                <SetRemoveButton variant='outlined' color='error' size='medium' sx={{ minWidth: isMobile ? 'unset' : 'default', width: '10px' }} onClick={() => (handleRemoveSet(exercise, index2))}>X</SetRemoveButton>
                                <Divider flexItem orientation='vertical' sx={{ marginLeft: isMobile ? '5px' : '20px', bgcolor: 'white' }} />
                                <SetTypography>{index2 + 1}</SetTypography>
                                <RepsTextField
                                  size='small'
                                  autoComplete='off'
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  value={rw.reps === '' ? '' : rw.reps}
                                  onChange={(e) => handleRepsTextInput(e, exercise, index2)}
                                  inputProps={{ max: 999, inputMode: 'numeric' }}
                                  InputProps={{
                                    endAdornment: < InputAdornment position="end" style={{ color: 'white' }} >
                                      <Typography>reps</Typography>
                                    </InputAdornment>
                                  }}
                                />
                                <WeightTextField
                                  size='small'
                                  autoComplete='off'
                                  onKeyPress={(event) => {
                                    if (!/[0-9.]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  value={(rw.weight) === '' ? '' : rw.weight}
                                  onChange={(e) => (handleWeightTextInput(e, exercise, index2))}
                                  inputProps={{ inputMode: 'numeric' }}
                                  InputProps={{
                                    endAdornment: < InputAdornment position="end" style={{ color: 'white' }} >
                                      <Typography>lbs/kg</Typography>
                                    </InputAdornment>
                                  }}
                                />
                              </SetContainer>
                            )
                          }

                        </SetParentContainer>
                        <SetButtonDiv>
                          <Divider sx={{ bgcolor: '#38444D' }} />
                          <AddSetButton variant="contained" size="small" onClick={() => handleAddSet(exercise)}>Add Set</AddSetButton>
                        </SetButtonDiv>
                      </ExerciseContainer>
                    ) : null
                  )
                }
                <div>
                  <ButtonContainer>
                    <Button variant="contained" type="submit" size="large" onClick={handleAddExercise} sx={{ bgcolor: '#1D9BF0', marginBottom: '10px' }}>Add Exercise</Button>
                  </ButtonContainer>
                </div>
                {
                  isMobile &&
                  <>
                    <Modal
                      open={openModal}
                      onClose={handleCloseModal}
                      closeAfterTransition
                      slots={{ backdrop: Backdrop }}
                      slotProps={{
                        backdrop: {
                          timeout: 200,
                        },
                      }}
                    >
                      <Fade in={openModal}>
                        <StyledModalBox >
                          <LoadingButton type="button" size="medium" loading={isDeleteLoading} startIcon={isDeleteSuccess ? <CheckIcon /> : <DeleteForeverIcon sx={{ color: 'red' }} />} loadingPosition='start' sx={{
                            ':disabled': { bgcolor: '#ccc' },
                            color: 'red'
                          }} onClick={() => setOpenConfirmModal(true)} >Delete this routine template</LoadingButton>
                          <Button size="medium" startIcon={<AddBoxIcon />} onClick={(e) => (handleCreateRoutineButton(), setOpenModal(false))} sx={{ color: 'white' }}>CREATE New Routine Template</Button>
                          <Button onClick={() => setOpenModal(false)} sx={{ color: 'white' }}>Close</Button>
                        </StyledModalBox>
                      </Fade>
                    </Modal>
                    <Modal
                      open={openConfirmModal}
                      onClose={() => setOpenConfirmModal(false)}
                    >
                      <StyledConfirmModalBox >
                        <Typography style={{ color: 'white' }}>Are you sure you want to <span style={{ color: '#bb0606', fontStyle: 'italic' }}>Permanently Delete</span> this routine?</Typography>
                        {/* <Typography style={{ color: 'white' }}> Are you sure you want to Delete it?</Typography> */}
                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px', width: '100%  ' }}>
                          <Button variant='outlined' sx={{ marginRight: '10px', width: '50%' }} onClick={() => (handleDeleteRoutineButton(), setOpenConfirmModal(false))}>Yes</Button>
                          <Button variant='outlined' sx={{ width: '50%' }} onClick={() => setOpenConfirmModal(false)}>No</Button>
                        </div>
                      </StyledConfirmModalBox>
                    </Modal>
                  </>
                }
              </Stack>
            </>
          }
        </Wrapper>
      </Container >

    </>
  )
}

const Container = styled.div`
  /* width: 100%;
  height: 100%;
  background: #192633;
  position: fixed;
  display: flex;
  justify-content: center;
  overflow: auto; */
  ${baseContainerStyles}

`
const Wrapper = styled.div`
  width: ${isMobile ? '100%' : '85%'}; 
  background-color: #15202b;
  position: relative;
  overflow: auto;
  padding-bottom: ${Capacitor.isNativePlatform() ? 'calc(80px + env(safe-area-inset-bottom))' : '60px'};
`
const Navbar = styled.div`
  height: 60px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
`
const DividerStyle = styled(Divider)`
	//margin-top: 30px;
	margin-top: ${isMobile ? '30px' : '60px'};

`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`

const DeleteButtonContainer = styled.div`
  display: flex;
  margin-top: 10px;
  margin-left: 10%;
`

const ExerciseContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  position: relative;
  background: #15202B;
  //width: 80%;
  margin-top: 30px;
  min-height: 20vh;
  border: 1px solid #7C8A97;
`

const SetButtonDiv = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;

`
const AddSetButton = styled(Button)`
  width: 100%;
  justify-content: flex-start;
  border-radius: 1px;
  
  color: white;
  background-color: #15202B;
  padding-left: 11px;
  :hover {
    background-color: #2C3640;
  }
  :focus{
    outline: none;
  }
`
const ExerciseTextField = styled(TextField)({
  marginTop: '10px',
  marginLeft: '10px',
  width: '35%',
  height: '56px',
  '& .MuiInputLabel-root': {
    color: '#7C8A97',
  },
  '& label.Mui-focused': {
    color: 'lightgrey',
  },
  '& .MuiOutlinedInput-input': {
    color: 'white',
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#273340',
    '& fieldset': {
      borderColor: 'grey',
    },
    '&:hover fieldset': {
      borderColor: 'lightgrey',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'lightgrey',
    },
  },
});

const SetParentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
`

const SetContainer = styled.div`
  margin-top: 10px;
  margin-left: 11px;
  display:flex;
  flex-direction: row;
`

const SetRemoveButton = styled(Button)({

})

const SetTypography = styled.div`
  margin-left: ${isMobile ? '9px' : '20px'};
  color: white;
  font-size: 22px;
  min-width: 24px;
`

const StyledTitleTextField = styled(TextField)({
  marginTop: '2%',
  width: '80%',
  marginLeft: '10%',
  font: 'bold',
  '& label.Mui-focused': {
    color: 'lightgrey',
  },
  '& .MuiOutlinedInput-input': {
    color: 'white',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#808080',
    },
    '&:hover fieldset': {
      borderColor: 'lightgrey',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'lightgrey',
    },
    '& .MuiIconButton-root': {
      color: 'white',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#7C8A97',
  },
})

const StyledDescriptionTextField = styled(TextField)({
  marginLeft: '10%',
  width: '80%',
  '& label.Mui-focused': {
    color: 'lightgrey',
  },
  '& .MuiFilledInput-input': {
    color: 'white',
  },
  '& .MuiFilledInput-root': {
    '&:before': {
      borderBottomColor: '#808080',
    },
    '&:hover:before': {
      borderBottomColor: '#bdbdbd',
    },
    '&.Mui-focused:after': {
      borderBottomColor: 'lightgrey',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#7C8A97',
  },
})

const RepsTextField = styled(TextField)({
  marginLeft: '20px',
  width: '35%',
  '& .MuiInputLabel-root': {
    color: '#7C8A97',
  },
  '& label.Mui-focused': {
    color: 'lightgrey',
  },
  '& .MuiOutlinedInput-input': {
    color: 'white',
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#273340',
    '& fieldset': {
      borderColor: 'grey',
    },
    '&:hover fieldset': {
      borderColor: 'lightgrey',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'lightgrey',
    },
  },
});

const WeightTextField = styled(TextField)({
  paddingRight: '5px',
  marginLeft: '20px',
  width: '35%',
  '& .MuiInputLabel-root': {
    color: '#7C8A97',
  },
  '& label.Mui-focused': {
    color: 'lightgrey',
  },
  '& .MuiOutlinedInput-input': {
    color: 'white',
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#273340',
    '& fieldset': {
      borderColor: 'grey',
    },
    '&:hover fieldset': {
      borderColor: 'lightgrey',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'lightgrey',
    },
  },
});

const StyledMenu = styled(Menu)({
  '& .MuiPaper-root': {
    backgroundColor: '#192633',
    color: 'white'
  }
})


const StyledModalBox = styled(Box)`
  position: absolute;
  background-color: #2c353b;
  bottom: 0;
  left: 0;
  width: 100%;
  border: 2px solid #2c353b;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  box-shadow: 24;
  display: flex;
  flex-direction: column;
  gap: 3px;
`
const StyledConfirmModalBox = styled(Box)`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center; 
  background-color: #272727;
  width: 60%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid #272727;
  border-radius: 5px;
  box-shadow: 24;
  padding: 6px;
  
`

export default observer(DailyRoutineComponent)