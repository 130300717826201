import { Capacitor } from '@capacitor/core';
import { Config } from './types';

const config: Config = {
    urls: [/localhost:\d+/],
    backend: {
        url: (Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'android') ? 'http://10.0.2.2:8000/v1/' : 'http://localhost:8000/v1/',
        //url: 'http://ec2-3-82-60-24.compute-1.amazonaws.com:8000/v1/',
    },
    logout: {
        url: 'http://localhost:3000/login',
    },
    cookieSecure: false,
};

export default config;
