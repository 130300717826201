// import { ViewState } from '@devexpress/dx-react-scheduler';
// import {
//   Scheduler, WeekView, Appointments, ViewSwitcher,
//   Toolbar,
//   MonthView,
//   DateNavigator,
//   TodayButton,
//   Resources,
//   DayView,
// } from '@devexpress/dx-react-scheduler-material-ui';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import loggedRoutineStore from '../../store/loggedRoutineStore';
import workoutRoutineStore from '../../store/workoutRoutineStore';
import { observer } from 'mobx-react-lite';
import { set, toJS } from 'mobx';
import LoggedRoutine from '../../types/Logged/LoggedRoutine';
import axios from 'axios';
import config from '../../config';
import { useAuth0 } from '@auth0/auth0-react';
import { isMobile } from 'react-device-detect';
import { CircularProgress } from '@mui/material';
import Cookies from 'js-cookie';
import { checkCookies } from '../../libs/api';
import { deleteRoutineStatePref, getPhotoUrl, getToken, getUserId } from '../../utils/mobileStorage';
import Scheduler, { Resource } from 'devextreme-react/scheduler';
// import 'devextreme/dist/css/dx.material.blue.light.css';
// import 'devextreme/dist/css/dx.material.blue.light.compact.css';
import { ViewType } from 'devextreme/ui/scheduler';
// import './calenderStyle.css'
if (isMobile) {
  require('devextreme/dist/css/dx.material.blue.light.compact.css');
  require('./calenderStyle.css')
} else {
  require('devextreme/dist/css/dx.material.blue.light.css');
  require('./calenderStyle.css')
}

const resources = [{
  fieldName: 'type',
  title: 'Type',
  instances: [
    { id: 'dailyRoutine', text: 'DailyRoutine', color: ' #192633' },
    { id: 'loggedRoutine', text: 'LoggedRoutine', color: '#50C878' },
    { id: 'todayRoutine', text: 'TodayRoutine', color: '#FFB940' },
    { id: 'restDay', text: 'restDay', color: '#444444c0' },
  ],
}];


const resourcesData = [
  {
    text: 'DailyRoutine',
    id: 1,
    color: '#192633',
  }, {
    text: 'LoggedRoutine',
    id: 2,
    color: '#50C878',
  }, {
    text: 'TodayRoutine',
    id: 3,
    color: '#FFB940',
  }, {
    text: 'restDay',
    id: 4,
    color: '#444444c0',
  },
];


const CalenderComponent = () => {
  // const { isLoading } = useAuth0()
  const [isDataloading, setIsDataLoading] = useState(true);
  const [calenderData, setCalenderData] = useState([])
  const [currentDate, setCurrentDate] = useState(new Date())
  const [currentViewName, setCurrentViewName] = useState(sessionStorage.getItem('viewState') || (isMobile ? 'day' : 'week'))
  const [currentDateFromHistory, setCurrentDateFromHistory] = useState(sessionStorage.getItem('currentDate') || currentDate)

  const [dailyRoutinesFetched, setDailyRoutinesFetched] = useState(false)

  const navigate = useNavigate();
  const moment = require('moment');
  const defaultDate = new Date()

  useEffect(() => {
    localStorage.removeItem('routineState')
    const fetch = async () => {
      setIsDataLoading(true)
      await Promise.all([
        deleteRoutineStatePref(),
        getPhotoUrl(),
        workoutRoutineStore.fetchRoutines(),
        loggedRoutineStore.fetchRoutines(),
      ])
      //setDailyRoutinesFetched(true)
      createSchedulerData(loggedRoutineStore.loggedRoutine)
      if (sessionStorage.getItem('currentDate') !== null) {
        handleDateClick(new Date(sessionStorage.getItem('currentDate')!))
      }
      setIsDataLoading(false)
    }
    fetch()
  }, [])
  // }, [dailyRoutinesFetched])

  // const handleAppointmentClick = (data: any) => {
  //   navigate(`/loggedroutine`, { state: data });
  // }

  const createSchedulerData = (loggedRoutines: LoggedRoutine[]) => {
    const schedulerData: any = [];
    const excludeDate: any = [];
    const dayOfWeekArray: string[] = []
    setIsDataLoading(true)
    for (const workout of loggedRoutines) {
      //get date and set its time to match calender ticker
      const startDate = moment(workout.futureDate).hour(9).minute(0).second(0).millisecond(0);
      const endDate = moment(startDate).hour(20).minute(0).second(0).millisecond(0);

      // const startDate = moment(workout.futureDate).startOf('day');
      // const endDate = moment(startDate).endOf('day');

      const startDateISO = startDate.format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      const endDateISO = endDate.format('YYYY-MM-DDTHH:mm:ss.SSSZ');

      const schedulerItem = {
        startDate: startDateISO,
        endDate: endDateISO,
        allDay: false,
        // title: workout.title,
        text: workout.title,
        routineState: toJS(workout),
        // type: startDate.isSame(moment(), 'day') ? 'todayRoutine' : 'loggedRoutine'
        typeId: startDate.isSame(moment(), 'day') ? [3] : [2]
      };
      excludeDate.push(startDate)
      schedulerData.push(schedulerItem);
    }
    for (const workout of workoutRoutineStore.dailyRoutines) {
      //ignore null days of week
      if (workout.dayOfWeek === null) continue
      dayOfWeekArray.push(workout.dayOfWeek)
      //get day of week and get its next occurance and set its time to match ticker
      const startDate = moment().day(workout.dayOfWeek).hour(9).minute(0).second(0).millisecond(0);
      const endDate = moment(startDate).hour(20).minute(0).second(0).millisecond(0);

      //check if startDate and endDate are before current date if they are add 7 to them
      if (startDate.isBefore(moment(), 'day')) {
        startDate.add(7, 'days');
        endDate.add(7, 'days');
      }

      let startDateISO = startDate.format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      let endDateISO = endDate.format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      //check if startDate is today and if it is not in excludeDate array make a single instance and make its type to todayRoutine
      if (startDate.isSame(moment(), 'day') && !excludeDate.some((date: any) => startDate.isSame(date, 'day'))) {
        const newSchedulerItem = {
          startDate: startDateISO,
          endDate: endDateISO,
          allDay: false,
          // title: workout.title,
          text: workout.title,
          recurrenceException: excludeDate.map((date: any) => moment.utc(date).format("YYYYMMDDTHHmmss[Z]")).join(', '),
          routineState: toJS(workout),
          // type: 'todayRoutine'
          typeId: [3]
        }
        excludeDate.push(startDateISO)
        schedulerData.push(newSchedulerItem);
        startDateISO = startDate.add(7, 'days').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
        endDateISO = endDate.add(7, 'days').format('YYYY-MM-DDTHH:mm:ss.SSSZ');;
      }
      //add the dailyRoutine to the schedulerData
      const schedulerItem = {
        startDate: startDateISO,
        endDate: endDateISO,
        allDay: false,
        // title: workout.title,
        text: workout.title,
        recurrenceRule: `FREQ=WEEKLY;BYDAY=${workout.dayOfWeek.substring(0, 2)}`,
        recurrenceException: excludeDate.map((date: any) => moment.utc(date).format("YYYYMMDDTHHmmss[Z]")).join(', '),
        routineState: toJS(workout),
        // type: 'dailyRoutine'
        typeId: [1]
      };
      schedulerData.push(schedulerItem);

    }
    //Create the rest days by checking if the day of week is not in the dayOfWeekArray
    const allDaysOfWeek = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];
    const restDays = allDaysOfWeek.filter(day => !dayOfWeekArray.includes(day));
    for (const restDay of restDays) {
      //get day of week and get its next occurance and set its time to match ticker
      const startDate = moment().day(restDay).hour(9).minute(0).second(0).millisecond(0);
      const endDate = moment(startDate).hour(20).minute(0).second(0).millisecond(0);

      //check if startDate and endDate are before current date if they are add 7 to them
      if (startDate.isBefore(moment(), 'day')) {
        startDate.add(7, 'days');
        endDate.add(7, 'days');
      }

      const startDateISO = startDate.format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      const endDateISO = endDate.format('YYYY-MM-DDTHH:mm:ss.SSSZ');


      const schedulerItem = {
        startDate: startDateISO,
        endDate: endDateISO,
        allDay: false,
        // title: 'Rest Day',
        text: 'Rest Day',
        recurrenceRule: `FREQ=WEEKLY;BYDAY=${restDay.substring(0, 2)}`,
        recurrenceException: excludeDate.map((date: any) => moment.utc(date).format("YYYYMMDDTHHmmss[Z]")).join(', '),
        // type: 'restDay'
        typeId: [4]
      };
      schedulerData.push(schedulerItem);

    }
    setCalenderData(schedulerData)
    setIsDataLoading(false)
  }

  const handleDateClick = async (date: Date) => {
    setCurrentDateFromHistory(date)
    sessionStorage.setItem('currentDate', date.toISOString())
    //check currentDate compared to date range, if the date doesnt have the same month of currentDate refetch loggedWorkouts
    if (date.getMonth() !== currentDate.getMonth()) {
      if (date.getMonth() === defaultDate.getMonth()) {
        createSchedulerData(loggedRoutineStore.loggedRoutine)
        setCurrentDate(date)
      }
      else {
        try {
          await checkCookies();
          const currentDate = new Date(date);
          //get current month and year
          const currentMonth = currentDate.getMonth();
          const currentYear = currentDate.getFullYear();

          // Adjust the year and month if current month is Jan
          const prevMonth = currentMonth === 0 ? 12 : currentMonth;
          const prevYear = currentMonth === 0 ? currentYear - 1 : currentYear;
          // Adjust the year and month if current month is Dec
          const nextMonth = currentMonth === 11 ? 0 : currentMonth + 1;
          const nextYear = currentMonth === 11 ? currentYear + 1 : currentYear;

          //create start and end date
          const startDate = new Date(`${prevYear}-${(prevMonth)}`);
          const endDate = new Date(nextYear, nextMonth + 1, 0);
          const data = {
            userId: await getUserId(),
            startDate: startDate,
            endDate: endDate
          }
          const res = await axios.post(config.backend.url + 'loggedWorkout/search/date', data, {
            headers: {
              "Authorization": await getToken()
            }
          });
          const loggedRoutines = res.data?.data?.LoggedWorkouts
          createSchedulerData(loggedRoutines)
        }
        catch (e: any) {
          console.log(e)
        }
        setCurrentDate(date)
      }
    }
  }
  const styleTitle = (props: any) => {
    const isWeekView = props.type === 'vertical'
    const styled = isWeekView ? { fontSize: '22px', textAlign: 'center' } : { fontSize: '16px', textAlign: 'center' }
    // if (isWeekView) {
    //   return (<Appointments.AppointmentContent
    //     {...props}
    //     style={styled}
    //   >
    //     <div className="VerticalAppointment-recurringContainer">
    //       <div className="VerticalAppointment-title">{props.data.title}</div>
    //     </div>
    //     {props.data.rRule &&
    //       < div className="VerticalAppointment-imageContainer">
    //         <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium VerticalAppointment-image css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="RepeatIcon">
    //           <path d="M7 7h10v3l4-4-4-4v3H5v6h2V7zm10 10H7v-3l-4 4 4 4v-3h12v-6h-2v4z"></path>
    //         </svg>
    //       </div>
    //     }
    //   </Appointments.AppointmentContent >)
    // }
    // return (<Appointments.AppointmentContent
    //   {...props}
    //   style={styled} />
    // )
  }

  // const handleViewChange = (viewName: any) => {
  //   setCurrentViewName(viewName)
  //   sessionStorage.setItem('viewState', viewName)
  //   //clear out stored date if view is changed
  //   setCurrentDateFromHistory(new Date())
  //   sessionStorage.removeItem('currentDate')
  //   handleDateClick(new Date())

  // }

  const handleAppointmentClick = (data: any) => {
    navigate(`/loggedroutine`, { state: data.targetedAppointmentData });
  }

  const EmptyTimeScale = () => null;

  const handleViewChange = (value: any) => {
    setCurrentViewName(value)
    sessionStorage.setItem('viewState', value)
    //clear out stored date if view is changed
    setCurrentDateFromHistory(new Date())
    sessionStorage.removeItem('currentDate')
    handleDateClick(new Date())

  };

  return (
    <>
      {isDataloading ?
        <div style={{ position: 'absolute', top: '15%', width: '100%', textAlign: 'center' }}>
          <CircularProgress />
        </div>
        :
        // <div style={{ height: isMobile ? '100%' : '100vh', flexFlow: 'column', display: 'flex', paddingBottom: isMobile ? '56px' : '' }}>
        //   <div style={{ height: currentViewName === 'Month' ? '695px' : '' }}>
        //     {/* // <Scheduler data={calenderData} >
        //   //   <ViewState currentViewName={currentViewName} currentDate={currentDateFromHistory} onCurrentDateChange={handleDateClick} onCurrentViewNameChange={handleViewChange} />
        //   //   <DayView
        //   //     startDayHour={9} endDayHour={20}
        //   //     timeScaleLayoutComponent={EmptyTimeScale}
        //   //     timeScaleLabelComponent={EmptyTimeScale}
        //   //     timeScaleTickCellComponent={EmptyTimeScale}
        //   //   />

        //   //   <WeekView
        //   //     startDayHour={9} endDayHour={20}
        //   //     timeScaleLayoutComponent={EmptyTimeScale}
        //   //     timeScaleLabelComponent={EmptyTimeScale}
        //   //     timeScaleTickCellComponent={EmptyTimeScale}
        //   //   />
        //   //   <MonthView />
        //   //   <Toolbar />
        //   //   <ViewSwitcher />
        //   //   <DateNavigator />
        //   //   <TodayButton />
        //   //   <Appointments
        //   //     appointmentComponent={(props) => {
        //   //       return <Appointments.Appointment
        //   //         {...props}
        //   //         onClick={(e) => handleAppointmentClick(props.data)}
        //   //       />
        //   //     }}
        //   //     appointmentContentComponent={styleTitle}
        //   //   />
        //   //   <Resources data={resources} />
        //   // </Scheduler> */}
        //   </div>
        //   <div style={{ backgroundColor: '#192633', flexGrow: '1' }} />
        // </div>
        <>
          <div style={{ height: isMobile ? '100%' : '100vh', flexFlow: 'column', display: 'flex', paddingBottom: isMobile ? '56px' : '' }}>
            <div style={{ height: currentViewName === 'Month' ? '695px' : '' }}>
              <Scheduler
                className={`base ${currentViewName === 'Week' ? '' : 'hide-time-panel'} ${currentViewName === 'month' ? 'month-view-title' : 'all-view-title'} ${(currentViewName === 'day' && isMobile) ? 'width-full-view' : ''} `}
                views={views as ViewType[]}
                currentView={currentViewName as ViewType}
                dataSource={calenderData}
                currentDate={currentDateFromHistory}
                // adaptivityEnabled={isMobile && true}
                onCurrentViewChange={handleViewChange}
                height={1000}
                startDayHour={9}
                showAllDayPanel={false}
                onAppointmentClick={handleAppointmentClick}
                editing={false}
                onCurrentDateChange={handleDateClick}
              >
                <Resource
                  fieldExpr="typeId"
                  allowMultiple={true}
                  dataSource={resourcesData}
                  label="Type"
                />
              </Scheduler>
            </div>
            <div style={{ backgroundColor: '#192633', flexGrow: '1' }} />
          </div>
        </>
      }
    </>

  );
}

const views = ['day', isMobile ? {
  type: 'agenda',
  name: 'Week',
} : 'week', 'month'];


export default observer(CalenderComponent);