import Cookies from "js-cookie"
import { isMobile } from "react-device-detect"
import { Preferences } from '@capacitor/preferences';
import { Capacitor } from "@capacitor/core";

export const getToken = async () => {
    if (Capacitor.isNativePlatform()) {
        const { value } = await Preferences.get({ key: 'jwt' });
        return `Bearer ${value}`
    } else {
        return `Bearer ${Cookies.get('jwt')}`
    }
}

export const getUserId = async () => {
    if (Capacitor.isNativePlatform()) {
        const { value } = await Preferences.get({ key: 'userId' });
        return value
    } else {
        return Cookies.get('userId')
    }
}

export const getPhotoUrl = async () => {
    const { value } = await Preferences.get({ key: 'photoUrl' });
    if (value) sessionStorage.setItem('photoUrl', value)
}
export const getRoutineStatePref = async () => {
    const { value } = await Preferences.get({ key: 'routineState' });
    return value ? value : null
}


export const setUserId = async (userId: string) => {
    await Preferences.set({
        key: 'userId',
        value: userId
    });
}

export const setToken = async (token: string) => {
    await Preferences.set({
        key: 'jwt',
        value: token
    });
}

export const setPhotoUrl = async (photoUrl: string) => {
    await Preferences.set({
        key: 'photoUrl',
        value: photoUrl
    });
}

export const setRoutineStatePref = async (routineState: string) => {
    await Preferences.set({
        key: 'routineState',
        value: routineState
    });
    localStorage.setItem('routineState', 'true')
}

export const removeAllPref = async () => {
    await Preferences.remove({ key: 'jwt' })
    await Preferences.remove({ key: 'userId' })
    await Preferences.remove({ key: 'photoUrl' })
    await Preferences.remove({ key: 'routineState' })
}
export const deleteRoutineStatePref = async () => {
    await Preferences.remove({ key: 'routineState' })
}