import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar';
import RoutineComponent from '../components/routines/DailyRoutineComponent';
import { useNavigate } from 'react-router-dom';
import getDayOfWeek from '../utils/GetDayOfWeek';
import MobileBottomBar from '../components/Mobile/MobileBottomBar';
import { isMobile } from 'react-device-detect';

const RoutinePage = (props: any) => {
  const navigate = useNavigate();
  const [dayOfWeek, setDayOfWeek] = useState<string | undefined>(getDayOfWeek());
  useEffect(() => {
    const currentDayOfWeek = getDayOfWeek()
    if (currentDayOfWeek === undefined) {
      alert("page 404 not found")
      navigate('/routine');
    }
  }, [])
  return (
    <>
      <Navbar title={`${dayOfWeek?.charAt(0).toUpperCase()}${dayOfWeek?.slice(1).toLowerCase()}'s Routine`} />
      <RoutineComponent />
      {/* {isMobile && <MobileBottomBar />} */}
    </>

  )
}

export default RoutinePage