import localConfig from './local';
import prodConfig from './production';

type Environment = 'development' | 'production';

const configs = {
    development: localConfig,
    production: prodConfig,
};

const env = process.env.REACT_APP_NODE_ENV;
export default env ? configs[env as Environment] : localConfig;
