import React from 'react'
import ProgressComponent from '../components/ProgressComponent'
import Navbar from '../components/Navbar'
import MobileBottomBar from '../components/Mobile/MobileBottomBar'
import { isMobile } from 'react-device-detect'

const ProgressPage = (props: any) => {
    return (
        <>
            <Navbar title={'Progress'} />
            <ProgressComponent />
            {isMobile && <MobileBottomBar />}
        </>
    )
}

export default ProgressPage