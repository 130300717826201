import { Routes, Route } from "react-router-dom"
// import CalenderPage from './pages/CalenderPage';
import HomePage from './pages/HomePage';
import DailyRoutinePage from './pages/DailyRoutinePage';
import WorkoutRoutinePage from './pages/WorkoutRoutinePage';
import LoggedWorkoutPage from './pages/LoggedWorkoutPage';
import ProgressPage from './pages/ProgressPage';
import Login from './pages/auth/Login';
import RequireAuth from './components/auth/RequireAuth';
import RedirectPage from './pages/auth/RedirectPage';
import Signup from './pages/auth/Signup';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import AthanReminderPrivacyPolicyPage from './pages/AthanReminderPolicyPage';

const App = () => {
    return (
        <>
            <Routes>
                {/* <Route path="/" element={<AuthenticationGuard component={HomePage} />} />
                <Route path="/routine" element={<AuthenticationGuard component={WorkoutRoutinePage} />} />
                <Route path="/routine/:id" element={<AuthenticationGuard component={DailyRoutinePage} />} />
                <Route path="/loggedroutine" element={<AuthenticationGuard component={LoggedWorkoutPage} />} />
                <Route path="/progress" element={<AuthenticationGuard component={ProgressPage} />} />
                <Route path="/redirect" element={<AuthenticationGuard component={RedirectPage} />} />
                <Route path="/login" element={<Login />} />
            <Route path="*" element={<div>Error 404 Page not Found</div>} /> */}
                <Route element={<RequireAuth />}>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/routine" element={<WorkoutRoutinePage />} />
                    <Route path="/routine/:id" element={<DailyRoutinePage />} />
                    <Route path="/loggedroutine" element={<LoggedWorkoutPage />} />
                    <Route path="/progress" element={<ProgressPage />} />
                    <Route path="/redirect" element={<RedirectPage />} />
                </Route>
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/privacy" element={<PrivacyPolicyPage />} />
                <Route path="/athanreminder/privacy" element={<AthanReminderPrivacyPolicyPage />} />
                <Route path="*" element={<div>Error 404 Page not Found</div>} />
            </Routes>
        </>
    );
}

export default App;