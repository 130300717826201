import { Capacitor } from '@capacitor/core'
import styled from '@emotion/styled'
import { CircularProgress } from '@mui/material'
const Container = styled.div`
    height: 100vh;
    width: 100vw;
    background-color: #192633;
`

const RedirectPage = () => {
    return (
        <Container>
            {
                Capacitor.isNativePlatform() ?
                    <div style={{ position: 'absolute', top: '10%', width: '100%', textAlign: 'center' }}>
                        <CircularProgress />
                    </div>
                    :
                    <div style={{ color: "white" }}>Redirecting you...</div>
            }
        </Container>
    )
}

export default RedirectPage