import { Config } from './types';

const config: Config = {
    urls: [/localhost:\d+/],
    backend: {
        url: 'https://api.letsgetswole.com/v1/',
    },
    logout: {
        url: 'https://www.letsgetswole.com/login',
    },
    cookieSecure: true,
};

export default config;
