import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import { Link, useNavigate } from 'react-router-dom';
import { Typography, AppBar, Tooltip, IconButton, Avatar, Menu, MenuItem } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { isMobile } from 'react-device-detect';
import { onAuthStateChanged, User } from 'firebase/auth';
import { auth } from '../libs/firebase';
import Cookies from 'js-cookie';
import { Capacitor } from '@capacitor/core';
import { getPhotoUrl, removeAllPref } from '../utils/mobileStorage';
import { Preferences } from '@capacitor/preferences';
import MobileTopBar from './Mobile/MobileTopBar';

const Container = styled(AppBar)`
    height: 60px;
    background-color: #192633;
    border-bottom: 2px solid;
    border-color: #38444D;
    display: flex;
    overflow-y: hidden;
    overflow-x: hidden;
`
const Wrapper = styled.div`
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const Left = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
`
const RoutineText = styled(Typography)`
    cursor: pointer;
    color:white;
    padding-left: 5px;
`
const WeightIconContainer = styled.div`
    display: flex;
    margin-left: 20px;
    :hover{
        text-decoration: underline;
        text-decoration-color: #2C3640;
        background-color: #2C3640;
    }
`

const SearchIconContainer = styled.div`
    display: flex;
    margin-right: 20px;
    :hover{
        text-decoration: underline;
        text-decoration-color: #2C3640;
        background-color: #2C3640;
    }
`

const Center = styled.div`
    flex: 1;
    text-align: center;
`
const Logo = styled(Typography)`
    font-weight: bold;
    color: white;
    padding-left: 2%;
    :hover{
        text-decoration: underline;
        text-decoration-color: #2C3640;
        background-color: #2C3640;
    }
`
const Right = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`

const MenuItems = styled.div`  
    cursor: pointer;
    margin-right: 20px;
`
const Navbar = (props: any) => {
    const navigate = useNavigate()
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLogout = async () => {
        Cookies.remove('jwt')
        Cookies.remove('userId')
        Cookies.remove('photoUrl')
        //clear storage 
        sessionStorage.clear()
        await auth.signOut()
        navigate('/login')
    }
    return (
        <>
            {
                isMobile ?
                    <>
                        <MobileTopBar title={props.title} />
                    </>
                    :
                    <>
                        <Container position='static'>
                            <Wrapper>
                                <Left>
                                    <WeightIconContainer>
                                        <Link to="/routine" style={{ textDecoration: 'none', alignItems: 'center', display: 'flex' }}>
                                            <div className="d-none d-md-block">
                                                <FitnessCenterIcon color="primary" sx={{ fontSize: 25, marginTop: '3px' }} />
                                            </div>
                                            <RoutineText variant={'h6'}>Routine</RoutineText>
                                        </Link>
                                    </WeightIconContainer>
                                </Left>
                                <Center>
                                    <Link to="/">
                                        <Logo variant={'h4'}>Swole</Logo>
                                    </Link>
                                </Center>
                                <Right>
                                    <SearchIconContainer>
                                        <Link to='/progress' style={{ textDecoration: 'none', alignItems: 'center', display: 'flex' }}>
                                            {
                                                <>
                                                    <div className="d-none d-md-block">
                                                        <SearchIcon sx={{ fontSize: 25, marginTop: '5px' }} />
                                                    </div>
                                                    <RoutineText variant='h6'>Progress</RoutineText>
                                                </>
                                            }
                                        </Link>
                                    </SearchIconContainer>
                                    <MenuItems>
                                        <Tooltip title="Open settings">
                                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                                {Cookies.get('photoUrl') ? <Avatar src={Cookies.get('photoUrl')} imgProps={{ referrerPolicy: "no-referrer" }} sx={{ width: '40px', height: '40px' }} /> : <AccountCircle sx={{ color: 'white' }} />}
                                            </IconButton>
                                        </Tooltip>
                                        <Menu
                                            sx={{ mt: '45px' }}
                                            id="menu-appbar"
                                            anchorEl={anchorElUser}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            open={Boolean(anchorElUser)}
                                            onClose={handleCloseUserMenu}
                                        >
                                            <MenuItem onClick={handleLogout}>
                                                <Typography textAlign="center">Logout</Typography>
                                            </MenuItem>
                                            {/* <MenuItem onClick={handleCloseUserMenu}>
                                    <Typography textAlign="center">{user?.name ? user?.name : 'User'}</Typography>
                                </MenuItem> */}
                                        </Menu>
                                    </MenuItems>
                                </Right>
                            </Wrapper>
                        </Container>
                    </>
            }
        </>

    )
}

export default Navbar